@media screen and (max-width: 1024px) {
    .top-students-heading h1 {
        font-size: 20px;
    }
    .student-mark {
        width: 500px;
    }
    .student-mark .change-average-type button {
        font-size: 18px;
    }
    .top-students-list {
        padding: 15px;
    }
    .top-students-list h3 {
        font-size: 14px;
    }
    .top-students-list h1 {
        font-size: 18px;
    }
    .coders-club-persons {
        transform: scale(0.7);
        right: -70px;
    }
    .coders-club-inner .club-logo {
        width: 350px;
        height: 100px;
    }
    .coders-club-inner p {
        font-size: 20px;
        line-height: 26px;
        margin: 20px 0;
    }
    .navigation nav ul {
        column-gap: 60px;
    }
    .novruz h3 {
        font-size: 50px;
        line-height: 60px;
    }
}
@media screen and (max-width: 900px) {
    .books-list {
        grid-template-columns: repeat(4, 1fr);
    }
    .mark-page-container {
        flex-direction: column-reverse;
    }
    .student-mark {
        width: 100%;
    }
    .top-students {
        width: 80%;
        margin: 15px auto;
    }
    .welcome {
        margin: 0 auto;
    }
    .navigation {
        margin-top: 45px;
    }
    main.home {
        justify-content: flex-start;
        padding-top: 20px;
    }
    .navigation nav ul {
        column-gap: 45px;
        justify-content: center;
    }
}
@media screen and (max-width: 768px) {
    .novruz .novruz__flower {
        width: 90%;
        height: 400px;
        object-fit: cover;
    }
    .novruz h3 {
        width: 95%;
        font-size: 36px;
        line-height: 45px;
        margin-top: 20px;
    }
    .novruz {
        padding-top: 40px;
    }
    .books-list {
        grid-template-columns: repeat(3, 1fr);
    }
    .welcome .user-details h1 {
        font-size: 36px;
    }
    header .user-name,
    .headerNavigation-container > p {
        font-size: 18px;
    }
    .active-language p {
        margin: 0 5px;
    }
    .headerNavigation-container > p img {
        margin-left: 5px;
    }
    .active-language img {
        width: 30px;
    }
    header .logo a img {
        width: 120px;
    }
    .task-section-header {
        padding: 70px 0 20px 20px;
    }
    .coders-club {
        height: auto;
        padding: 10px 0;
    }
    .coders-club-persons-mobile {
        display: block;
        width: 312px;
    }
    .coders-club::before {
        display: none;
    }
    .coders-club-persons {
        display: none;
    }
    .coders-club-inner .club-logo {
        width: 172px;
        height: 60px;
    }
    .coders-club-inner {
        width: 100%;
        padding-top: 0;
    }
    .coders-club-inner p {
        font-size: 16px;
        line-height: 24px;
        margin: 15px 0;
    }
    .support-modal-container {
        display: block;
    }
    .support-modal-container form {
        margin: 20px auto;
    }
    .navigation nav ul {
        column-gap: 5%;
    }
}
@media screen and (max-width: 672px) {
    .edit-profile-body label {
        font-size: 13px;
    }
    .create_online_lesson,
    .destroy_online_lesson {
        font-size: 12px;
        padding: 8px;
        margin-top: 8px;
    }
    .books-list {
        grid-template-columns: repeat(2, 1fr);
    }
    .login-container {
        background-color: #fff;
        background-image: none;
    }
    .login-form {
        padding-bottom: 30px;
    }
    .login-form form {
        margin: 30px auto;
        padding: 5px;
    }
    .login-form .logo {
        filter: brightness(0) !important;
        margin-bottom: 0;
    }
    .login-form .p-50 {
        padding: 20px 0;
    }
    .login-form .form-group {
        border: 1px solid #e8e8e8;
        box-shadow: 0px 8px 13px rgba(0, 0, 0, 0.15);
        border-radius: 25px;
        margin-bottom: 30px;
    }
    .login-form .form-group button {
        background: linear-gradient(267.57deg, #63b22f -3.55%, #2eb8cd 100%);
        box-shadow: 0px 8px 13px rgba(238, 42, 52, 0.15);
        border-radius: 32px;
    }
    .login-social-media {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 24px;
    }
    .text-danger {
        font-weight: 400;
        font-size: 12px;
        line-height: 9px;
        margin-top: 3px;
    }
    .absent-count .text-danger {
        font-size: 18px;
    }
    header {
        padding: 0 20px;
        height: 60px;
    }
    header .logo a img {
        width: 115px;
        height: 25px;
    }
    .responsive-menu-toggler {
        display: block;
    }
    .menu-container {
        display: none;
    }
    .welcome {
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
        border-radius: 10px;
        margin: 10px auto;
        padding: 10px;
    }
    .welcome .user-image img {
        width: 60px;
        height: 60px;
        border-width: 3px;
    }
    .welcome .user-details h1 {
        font-size: 20px;
    }
    .welcome .user-image {
        margin-right: 15px;
    }
    .welcome .user-details .group-info {
        font-size: 10px;
        padding: 5px 10px;
        margin-top: 10px;
    }
    footer ul {
        padding: 20px 0;
    }
    footer ul li {
        margin-right: 10px;
    }
    footer ul li a {
        width: 40px;
        height: 40px;
    }
    footer ul li a img {
        width: 25px;
        height: 25px;
    }
    .headerNavigation-container {
        display: none;
    }
    .navigation {
        margin: 0;
    }
    .student-mark .change-average-type button {
        padding: 13px 17px;
        font-size: 14px;
    }
    .top-students-heading h1 {
        font-size: 15px;
        line-height: 24px;
    }
    .instructor-groups {
        margin: 30px auto;
    }
    .task-section-header h1 {
        font-size: 22px;
    }
    .task-accordion-body p {
        font-size: 18px;
        line-height: 20px;
    }
    .task-details-header .task-name h1 {
        font-size: 24px;
        line-height: 20px;
        margin-bottom: 10px;
    }
    .task-details-header .task-name {
        margin-left: 0;
    }
    .task-details-body .task-file {
        margin-top: 25px;
        width: 300px;
        height: 65px;
    }
    .task-details-body .task-file .icon {
        width: 70px;
    }
    .task-details-body .task-file .download-task img {
        width: 35px;
        height: 35px;
    }
    .task-details-body .task-file .task-file-details * {
        font-size: 14px;
        line-height: 10px;
    }
    .task-details-body .task-file .download-task {
        right: -10px;
    }
    .task-section-header {
        padding: 40px 0 15px 15px;
    }
    .not_found_404 h1 {
        font-size: 70px;
    }
    .not_found_404 div {
        bottom: 50px;
    }
    .not_found_404 p {
        margin: 10px auto 0 auto;
    }
    main.home {
        min-height: auto !important;
    }
    .navigation nav ul {
        justify-content: flex-start;
    }
    .navigation nav ul li a .nav-icon {
        height: 100px;
    }
    .navigation nav ul > div {
        width: 100px;
    }
    .navigation nav ul > div img {
        width: 60px;
        height: 60px;
    }
    .navigation nav ul li a {
        font-size: 14px !important;
    }
    .mobile-sidebar .change-language {
        margin: 15px auto !important;
    }
    .happy_birthdays_inner ul li {
        width: 100%;
    }
    .all_hbd {
        overflow: auto;
    }
    #hbd_overlay {
        height: 55vh;
        object-fit: cover;
    }
    .cafe__bg ul {
        font-weight: 400;
        font-size: 12px;
        line-height: 17px;
    }
    .cafe__bg {
        margin: 25px auto;
        max-width: 100%;
        padding: 0 12px;
    }
    .cafe__heading img {
        max-width: 240px;
    }
    .price__list span:last-of-type {
        width: 80px;
    }
    .price__list span:first-of-type {
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    .cafe__section {
        padding: 20px 0;
    }
    .cafe__bg h3 {
        font-weight: 400;
        font-size: 20px;
        line-height: 17px;
    }
    .cafe__logo__bottom {
        width: 90px;
        margin-top: 40px;
    }
}
@media screen and (max-width: 425px) {
    .novruz h3 {
        font-size: 32px;
        line-height: 40px;
    }
    .student_inner_data {
        flex-direction: column;
        gap: 5px;
        align-items: flex-start;
    }
    .join_online_lesson {
        padding: 5px 15px;
        margin-top: 0;
    }
    .add_online_lesson_modal_container form {
        font-size: 14px;
    }
    .instructor-groups {
        margin: 0 auto;
    }
    main.home {
        gap: 20px;
    }
    .books-list li img,
    .book-cover {
        height: 280px;
    }
    .add_birthday_inner {
        padding: 10px;
    }
    .add_birthday h1 {
        font-size: 22px;
    }
    .add_birthday input {
        margin: 20px 0;
    }
    .mark-input-container {
        padding-left: 10px;
    }
    .change-mark-date {
        width: 260px;
    }
    .mark-input-container input {
        font-size: 10px;
    }
    .mark-input-container label {
        left: 30px;
    }
    .task-accordion-header {
        font-size: 18px;
    }
    .task-accordion-body p {
        font-size: 16px;
        line-height: 18px;
    }
    .top-students-list li img:not(.crown) {
        width: 38px;
        height: 38px;
    }
    .student-marks-table .table-body li {
        font-size: 12px;
    }
    .support-modal-container form .cat_label,
    .support-modal-container form .title_label {
        width: 100%;
        max-width: 100%;
    }
    .navigation nav ul > div {
        width: 75px;
    }
    .navigation nav ul li a .nav-icon {
        height: 75px;
    }
    .navigation nav ul li a .nav-icon img {
        width: 40px;
        height: 40px;
    }
    .change-mark-date:before {
        left: 38% !important;
    }
}
@media screen and (max-width: 375px) {
    .navigation nav ul > div {
        width: 70px;
    }
    .navigation nav ul li a .nav-icon {
        height: 70px;
    }
    .navigation nav ul li a .nav-icon img {
        width: 35px;
        height: 35px;
    }
    .change-mark-date {
        width: 100%;
    }
    .mark-input-container input {
        font-size: 12px;
    }
    .student-mark-info .configure {
        position: absolute;
        top: 70px;
        left: 0;
    }
    .student-marks-table {
        margin-top: 70px;
        font-size: 12px;
    }
    .task-section-header h1 {
        font-size: 18px;
    }
    .task-details-body .task-file {
        width: 250px;
        margin-top: 20px;
    }
}
