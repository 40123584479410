@font-face {
    font-family: "CodecPro-Regular";
    src: url("../../assets/fonts/CodecPro-Regular.ttf");

}

@font-face {
    font-family: "CodecPro-Italic";
    src: url("../../assets/fonts/CodecPro-Italic.ttf");
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
    box-shadow: 0 0 0 30px white inset !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    appearance: none;
    -webkit-appearance: none;
    margin: 0;
}

input[type="number"] {
    appearance: none;
    -moz-appearance: textfield;
}

::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #f5f5f5;
}

::-webkit-scrollbar {
    width: 10px;
    background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
    background-color: #2eb8cd;
    background: linear-gradient(45deg, #63b22f 37.69%, #2eb8cd 100%);
}

body {
    background-color: #f9f9f9;
    overflow-x: hidden;
}

* {
    font-family: "CodecPro-Regular";
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    text-decoration: none;
    list-style: none;
}

.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    text-align: center;
    background: linear-gradient(63.18deg, #2eb8cd 17.16%, #63b22f 100%);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.login-form h1 {
    font-size: 24px;
    margin-top: 15px;
}

.login-form .p-50 {
    padding: 35px 0;
}

.login-form form {
    max-width: 95%;
    padding: 90px;
    /* background-color: #fff; */
    background-image: url("../../assets/images/site/login_bg.svg");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    /* background-position: center; */
    /* border-radius: 12px; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    position: relative;
}

.login-form .form-group {
    border: 1px solid #b8b8b8;
    max-width: 100%;
    width: 425px;
    height: 51px;
    border-radius: 5px;
    position: relative;
    margin-bottom: 48px;
}

.login-form .form-group.submit {
    margin-bottom: 0;
}

.login-form .form-group label {
    position: absolute;
    background-color: #fff;
    color: #747474;
    font-size: 12px;
    left: 18px;
    top: 0;
    transform: translateY(-50%);
    z-index: 1;
}

.login-form .form-group input {
    max-width: 100%;
    width: 177px;
    height: 95%;
    position: absolute;
    left: 53px;
    top: 50%;
    transform: translateY(-50%);
    background-color: #fff !important;
    border: 0;
    outline: 0;
    padding-right: 10px;
    font-size: 13px;
}

.login-form .form-group input[name="email"] {
    width: 200px;
}

.form-group .suffix {
    position: absolute;
    z-index: 1;
    right: 4px;
    top: 50%;
    transform: translateY(-50%);
    height: 100%;
    display: flex;
    align-items: center;
    font-size: 13px;
}

.login-form .form-group img {
    position: absolute;
    left: 19px;
    top: 50%;
    transform: translateY(-50%);
}

.black {
    color: #000;
}

.login-form .form-group button,
.btn {
    background: #2eb8cd;
    width: 100%;
    height: 100%;
    border: 0;
    outline: 0;
    border-radius: 5px;
    box-shadow: 0px 8px 13px rgba(238, 42, 52, 0.15);
    font-weight: 700;
    font-size: 18px;
    color: #fff;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.login-form .form-group.submit {
    border: 0 !important;
}

.login-form .form-group.password {
    margin-bottom: 30px;
}

.login-form {
    max-width: 100%;
}

.text-danger {
    color: #f24545;
}

.login-form .text-danger {
    margin-bottom: 20px;
}

.login-form .form-group .text-danger {
    position: absolute;
    top: 60px;
    left: 55%;
    transform: translateX(-50%);
    width: 100%;
    text-align: left;
}

.newsLogo,
.newsLogoHeaders,
.newsLogoMobil {
    color: white;
    text-transform: uppercase;
    font-weight: 700;
    text-align: end;
}

.newsLogo .logoTexts,
.newsLogoHeaders .logoTexts,
.newsLogoMobil .logoTexts {
    color: rgb(34, 34, 34);
    font-style: italic;
}

.newsLogoMobil {
    width: 20px;
    height: 20px;
    filter: brightness(200%);
    margin-right: 15px;
    padding-top: 6px;
}

.newsLogoMobil .logoTexts {
    color: white;
}

.newsLogoHeaders {
    font-size: 13px;
}

.newsLogoMobil {
    font-size: 5px;
}

header {
    height: 60px;
    background: #2eb8cd;
    /* background: linear-gradient(90deg, #2eb8cd 37.69%, #63b22f 100%); */
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 43px;
    padding-right: 49px;
    position: relative;
    z-index: 1;
}

header .logo a img {
    width: 165px;
    height: 42px;
}

header .logo {
    cursor: pointer;
}

header .menu {
    display: flex;
    align-items: center;
    color: #fff;
    margin-left: 12px;
}

header .menu .user-image {
    width: 42px;
    height: 42px;
    border-radius: 50%;
    object-fit: cover;
    border: 4px solid #2eb8cd6c;
}

header .user-name {
    font-size: 24px;
    font-weight: lighter;
    margin-left: 10px;
    display: flex;
    align-items: center;
    cursor: pointer;
}

header .user-name img {
    width: 16px;
    height: 8px;
    margin-left: 8px;
}

.mobile-sidebar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    overflow-y: scroll;
    background: linear-gradient(30.97deg, #2eb8cd 0%, #63b22f 126.14%);
    box-shadow: 4.51779px 4.51779px 5.64723px -2.82362px rgba(0, 27, 31, 0.25);
    padding: 20px;
    padding-bottom: 0;
    color: #fff;
    clip-path: circle(0% at 100% 0);
    opacity: 0;
    visibility: hidden;
    transition: 0.3s;
    z-index: 999;
}

.mobile-sidebar.active {
    clip-path: circle(141.2% at 100% 0);
    opacity: 1;
    visibility: visible;
}

.mobile-sidebar .logo img {
    width: 115px;
    height: 25px;
}

.mobile-sidebar-header {
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.mobile-sidebar-user {
    text-align: center;
}

.mobile-sidebar-user h1 {
    color: #fff;
}

.mobile-sidebar .change-language {
    margin: 40px auto;
    width: max-content;
}

.mobile-sidebar .change-language .active-language {
    border-right: 0;
}

.mobile-sidebar .change-language .active-language .icon {
    width: 14px;
    height: 14px;
}

.mobile-navigation {
    margin-top: 5px;
}

.mobile-navigation a {
    color: #fff;
    display: flex;
    align-items: center;
}

.mobile-navigation li {
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
    padding: 5px 0;
}

.mobile-navigation-bottom li:last-of-type {
    border-bottom: 0;
}

.mobile-navigation a {
    font-size: 14px;
}

.mobile-navigation a.active {
    color: #c85656 !important;
}

.mobile-navigation a img {
    width: 20px;
    height: 20px;
    filter: brightness(200%);
    margin-right: 15px;
}

.mobile-sidebar-user img {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    border: 4px solid rgba(255, 255, 255, 0.6);
    margin: 15px 0;
}

.change-language {
    position: relative;
}

.language-dropdown {
    position: absolute;
    background-color: #fff;
    padding: 5px 10px;
    box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.08);
    border-radius: 5px;
    z-index: 9;
    top: 100%;
    left: 0;
    transition: 0.4s;
    transform: translateY(26px);
    opacity: 0;
    visibility: hidden;
}

.language-dropdown.shown {
    transform: translateY(10px);
    opacity: 1;
    visibility: visible;
}

.language-dropdown li {
    display: flex;
    align-items: center;
    font-size: 16px;
    cursor: pointer;
    margin-bottom: 10px;
}

.language-dropdown li:last-of-type {
    margin-bottom: 0;
}

.language-dropdown li p {
    margin-left: 18px;
}

.active-language {
    display: flex;
    align-items: center;
    color: #fff;
    border-right: 2px solid #fff;
    padding-right: 14px;
    cursor: pointer;
}

.active-language img {
    width: 45px;
    height: 30px;
    border-radius: 5px;
}

header img.icon {
    width: 14px;
    height: 14px;
    transition: 0.3s;
    border: 0;
}

.active-language p {
    margin: 0 8px;
    font-size: 12px;
}

.language-dropdown img {
    width: 35px;
    height: 23px;
    margin-right: 18px;
    cursor: pointer;
    border-radius: 5px;
}

.menu-container {
    position: relative;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
}

.responsive-menu-toggler {
    display: none;
}

.menu-dropdown {
    background: #fff;
    border: 0.5px solid #dbdbdb;
    box-shadow: 0px 0px 7px 1px rgba(0, 0, 0, 0.08);
    border-radius: 5px;
    position: absolute;
    width: 330px;
    bottom: -5px;
    right: 0;
    transform: translate(0%, 100%);
    z-index: 99;
    display: none;
}

.menu-dropdown-active {
    display: block;
}

.menu-dropdown a {
    color: #000;
    display: inline-block;
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #dbdbdb;
    padding: 0 12px;
    transition: 0.3s;
}

.menu-dropdown a:hover {
    filter: brightness(0) saturate(100%) invert(62%) sepia(91%) saturate(409%) hue-rotate(140deg) brightness(88%) contrast(80%);
}

.menu-dropdown li:last-of-type a {
    border-bottom: none;
}

.d-none {
    display: none;
}

.welcome {
    width: 900px;
    max-width: 95%;
    display: flex;
    align-items: center;
    margin: 0 auto;
}

.welcome .user-image {
    margin-right: 50px;
}

.welcome .user-image img {
    width: 112px;
    height: 112px;
    border-radius: 50%;
    border: 8px solid #2eb8cd6c;
    object-fit: cover;
}

.welcome .user-details h1 {
    font-size: 36px;
    font-weight: lighter;
}

.colored {
    color: #2eb8cd;
    font-weight: 700;
}

.welcome .user-details .group-info {
    width: max-content;
    background: #f0f0f0;
    border: 1px solid #d6d6d6;
    border-radius: 5px;
    padding: 10px 24px;
    margin-top: 20px;
    font-weight: 400;
    font-size: 16px;
}

.navigation nav ul {
    width: 1160px;
    max-width: 95%;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    column-gap: 85px;
    row-gap: 25px;
}

.navigation nav ul li:last-of-type {
    margin-right: 0;
}

.navigation nav ul>div {
    width: 120px;
}

.navigation nav ul li {
    width: 100%;
    text-align: center;
    cursor: pointer;
}

.no-hover {
    pointer-events: none;
}

.navigation nav ul li:not(.no-hover):hover .nav-icon,
.headerNavigation li:not(.no-hover):hover .nav-icon {
    background: #fff;
    border-color: #2eb8cd;
}


.navigation nav ul li:hover:not(.no-hover) .nav-icon img,
.headerNavigation li:hover:not(.no-hover) .nav-icon img {
    filter: brightness(0) saturate(100%) invert(84%) sepia(42%) saturate(4041%) hue-rotate(146deg) brightness(88%) contrast(80%);
}

.navigation nav ul li:hover:not(.no-hover) .nav-icon .newsLogo,
.headerNavigation li:hover:not(.no-hover) .nav-icon .newsLogo,
.navigation nav ul li:hover:not(.no-hover) .nav-icon .newsLogoHeaders,
.headerNavigation li:hover:not(.no-hover) .nav-icon .newsLogoHeaders {
    filter: brightness(0) saturate(100%) invert(84%) sepia(42%) saturate(4041%) hue-rotate(146deg) brightness(88%) contrast(80%);
}

.no_invert:hover img {
    filter: none !important;
}

.navigation nav ul li a {
    width: 100%;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    font-size: 20px;
    font-weight: 300;
    color: #000;
}

.active {
    color: #2eb8cd !important;
}

.navigation nav ul li a .nav-icon {
    border: 1px solid transparent;
    width: 100%;
    height: 120px;
    background: linear-gradient(30.97deg, #2eb8cd 0%, #63b22f 126.14%);
    box-shadow: 8px 8px 10px -5px rgba(0, 27, 31, 0.25);
    border-radius: 26px;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    display: flex;
    align-items: center;
    transition: 0.3s;
    position: relative;
}

footer ul {
    display: flex;
    width: max-content;
    margin: 0 auto;
}

footer ul li {
    margin-right: 30px;
}

footer ul li:last-of-type {
    margin-right: 0;
}

footer ul li a {
    background-color: #2eb8cd;
    width: 58px;
    height: 58px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    -webkit-box-reflect: below 10px linear-gradient(transparent 50%, rgba(255, 255, 255, 0.3));
}

.instructor-groups {
    width: 1200px;
    max-width: 95%;
    margin: 0 auto;
    border: 1px solid #d6d6d6;
    border-radius: 10px;
    padding: 17px 36px;
}

.instructor-groups h1 {
    font-size: 24px;
}

.instructor-groups ul {
    display: flex;
    flex-wrap: wrap;
    margin-top: 15px;
}

.instructor-groups ul li {
    margin-right: 15px;
    margin-bottom: 15px;
    cursor: pointer;
}

.instructor-groups ul li:last-of-type {
    margin-right: 0;
}

.instructor-groups ul li {
    background-color: #2eb8cd;
    padding: 10px;
    color: #fff;
    border-radius: 5px;
    display: inline-block;
    transition: 0.3s;
}

.instructor-groups ul li:hover {
    transform: scale(1.05);
}

.mark-modal-container {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    opacity: 0;
    visibility: hidden;
    left: 0;
    z-index: 10;
    backdrop-filter: blur(5px);
    background-color: rgba(0, 0, 0, 0.4);
    transition: 0.3s;
}

.mark-modal-container-active {
    opacity: 1 !important;
    visibility: visible !important;
}

.mark-modal-container .mark-modal {
    width: 800px;
    max-width: 95%;
    max-width: 90%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.mark-modal-container .mark-modal-inner {
    background: #fff;
    border: 2px solid #2eb8cd;
    border-radius: 10px;
    padding: 22px 19px;
    position: relative;
}

.mark-modal-heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.mark-modal-container .group-name {
    text-align: center;
    color: #000;
    font-weight: 500;
    font-size: 28px;
    margin-bottom: 27px;
    font-weight: bold;
}

.mark-modal-heading .mark-date {
    width: 190px;
    height: 45px;
    display: flex;
    align-items: center;
    border: 0.5px solid #b0b0b0;
    border-radius: 10px;
    padding: 0 10px;
    position: relative;
    cursor: pointer;
}

.mark-modal-heading .mark-date input {
    font-size: 13px;
    color: #2eb8cd;
    font-weight: 400;
    border: 0;
    height: 30px;
    cursor: pointer;
    width: 105px;
    outline: none;
}

.mark-modal-heading .mark-date p {
    font-size: 8px;
}

.mark-modal-heading .mark-date>div {
    margin-left: 5px;
}

.mark-modal-heading .mark-date>button {
    background: #2eb8cd;
    border-radius: 0px 10px 10px 0px;
    border: 0;
    width: 52px;
    height: 105%;
    cursor: pointer;
    position: absolute;
    right: -5px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
}

.mark-modal-heading .task-upload {
    width: max-content;
    display: flex;
    align-items: center;
    border: 0.5px solid #b7b7b7;
    border-radius: 5px;
    padding: 12px 14px;
}

.mark-modal-heading .task-upload button {
    border: 0;
}

.mark-modal-heading .task-upload .upload {
    padding: 8px 6px;
    background: #2eb8cd;
    border-radius: 2px;
    cursor: pointer;
    color: #fff;
}

.mark-modal-heading .task-upload .cancel {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #b3b3b3;
    cursor: pointer;
    margin-left: 12px;
}

.mark-modal-container .close-modal {
    background: #ff8a8a;
    border-radius: 4px;
    position: absolute;
    right: 0;
    top: 0;
    border: 0;
    cursor: pointer;
    width: 21px;
    height: 21px;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translate(100%, -100%);
}

.mark-modal-container .mark-modal-students {
    margin-top: 34px;
    min-height: 425px;
    max-height: 525px;
    overflow-y: scroll;
    position: relative;
}

rect {
    fill: red;
}

.mark-modal-container .mark-modal-students li {
    width: 98%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #ececec;
    border-radius: 5px;
    padding: 12px 20px;
    margin-bottom: 10px;
    font-size: 18px;
}

.mark-modal-container .mark-modal-students li:last-of-type {
    margin-bottom: 0;
}

.mark-modal-container .mark-modal-students li input {
    border: 0;
    outline: 0;
    width: 54px;
    height: 22px;
    font-size: 12px;
    padding: 5px;
}

.mark-modal-container .mark-modal-students li input::placeholder {
    font-size: 8px;
}

.mark-modal-container .mark-modal-students li input::-webkit-inner-spin-button {
    display: none;
}

.mark-modal-container .mark-modal-students li .absent,
.mark-modal-container .mark-modal-students li .plus {
    border: 0;
    outline: 0;
    width: 19px;
    height: 19px;
    background: #a0a0a0;
    border-radius: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.mark-modal-container .mark-modal-students li .absent img,
.mark-modal-container .mark-modal-students li .plus img {
    pointer-events: none;
}

.mark-modal-container .mark-modal-students li .absent {
    margin-left: 16px;
}

.mark-modal-container .mark-modal-students li .plus {
    margin-left: 23px;
}

.mark-modal-container .mark-modal-students .mark-operations {
    display: flex;
}

.mark-modal-container .mark-modal-inner .confirm-marks {
    background: #2eb8cd;
    border-radius: 5px;
    color: #fff;
    border: 0;
    outline: 0;
    font-size: 16px;
    padding: 10px;
    cursor: pointer;
    margin-top: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
}

.selected {
    background-color: #2eb8cd !important;
}

.absent.selected {
    background-color: #f24545 !important;
}

.border-red {
    border: 1px solid #f24545 !important;
}

.student-mark {
    width: 600px;
}

.student-mark-progress-container {
    width: 60%;
    height: 60px;
    margin: 0 auto;
    position: relative;
}

.student-mark-progress-container .absent-count {
    width: max-content;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    font-weight: 400;
    font-size: 20px;
    line-height: 17px;
    color: #6c6c6c;
}

.student-mark-progress-container .absent-count span {
    font-weight: 1000;
    color: #f24545;
}

.student-mark-progress {
    height: 5px;
    background-color: #2eb8cd;
    position: relative;
}

.student-mark-progress::before {
    content: "";
    position: absolute;
    width: 28px;
    height: 28px;
    top: 50%;
    left: 0;
    background-color: #2eb8cd;
    border-radius: 50%;
    transform: translate(-100%, -50%);
    z-index: -1;
}

.student-mark-progress::after {
    content: "";
    position: absolute;
    width: 28px;
    height: 28px;
    top: 50%;
    right: 0;
    background-color: #2eb8cd;
    border-radius: 50%;
    transform: translate(100%, -50%);
    z-index: -1;
}

.student-mark-progress-bar {
    width: 0;
    height: 100%;
    position: relative;
    font-weight: 1000;
    font-size: 26px;
    animation: movemark 0.5s 1 linear;
    transition: 1s;
}

@keyframes movemark {
    from {
        width: 0;
        transform: scale(0);
    }
}

.student-mark-progress-bar .count-up-mark {
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(50%, -250%);
}

.student-mark-progress-bar::before {
    content: "";
    position: absolute;
    width: 70px;
    height: 80px;
    top: 0;
    right: 0;
    transform: translate(50%, -100%);
    background-image: url("../../assets/icons/average-mark-background.svg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
}

.student-mark-progress span.min {
    position: absolute;
    top: 100%;
    left: -14px;
    transform: translate(-50%, 20px);
}

.student-mark-progress span.max {
    position: absolute;
    top: 100%;
    right: -14px;
    transform: translate(50%, 20px);
}

.student-mark .change-average-type {
    margin: 0 auto;
    width: max-content;
    display: flex;
    max-width: 100%;
}

.student-mark .change-average-type button {
    background: #e0e0e0;
    border: 0;
    padding: 20px 65px;
    cursor: pointer;
    display: inline-block;
    font-weight: 1000;
    font-size: 24px;
    margin-top: 15px;
    color: #b0b0b0;
    letter-spacing: 1px;
}

.student-mark .change-average-type button.activebtn {
    background-color: #2eb8cd;
    color: #fff;
}

.student-mark .change-average-type button:first-of-type {
    border-radius: 20px 0px 0px 20px;
}

.student-mark .change-average-type button:last-of-type {
    border-radius: 0px 20px 20px 0px;
    margin-left: 9px;
}

.student-mark-info {
    border: 2px solid #2eb8cd;
    border-radius: 10px;
    margin-top: 42px;
    padding: 21px 25px;
}

.student-mark-info-header {
    display: flex;
    justify-content: space-between;
    position: relative;
}

.change-mark-date {
    display: flex;
    width: max-content;
    height: 52px;
    border: 0.5px solid #b0b0b0;
    border-radius: 10px 0px 0px 10px;
    justify-content: space-between;
    position: relative;
}

.mark-input {
    color: rgb(18, 150, 102);
}

.mark-input-container {
    display: flex;
    width: 120px;
    position: relative;
    align-items: center;
    height: 100%;
    padding-left: 20px;
}

.mark-input-container input:disabled {
    background-color: initial !important;
}

[data-disabled="disabled"]::after {
    content: "";
    position: absolute;
    width: calc(100% + 15px);
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 10;
    border-radius: 10px;
    cursor: not-allowed;
}

.mark-input-container img {
    width: 20px;
    height: 20px;
    z-index: 4;
    pointer-events: none;
    transform: translateX(-10px);
}

.mark-input-container label {
    position: absolute;
    font-size: 8px;
    color: #757575;
    top: 8px;
    left: 45px;
    z-index: 1;
}

.mark-input-container input {
    width: 100%;
    height: 100%;
    outline: 0;
    border: 0;
    font-size: 12px;
    cursor: pointer;
    padding-left: 35px;
    z-index: -1;
    border-radius: 10px;
}

.mark-input-container .mark-input,
.mark-input-container .react-datepicker-wrapper,
.mark-input-container .react-datepicker__input-container {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 10px;
}

.change-mark-date .get_marks {
    width: 45px;
    height: 52px;
    background: #b0b0b0;
    border-radius: 0px 10px 10px 0px;
    border: 0;
    cursor: pointer;
    transform: translate(15px, -0.5px);
}

.change-mark-date::before {
    content: "";
    position: absolute;
    width: 1px;
    height: 70%;
    top: 50%;
    left: 44%;
    transform: translate(-50%, -50%);
    background-color: #b0b0b0;
    z-index: 2;
}

.student-mark-info .configure {
    border: 0;
    outline: 0;
    cursor: pointer;
    background-color: inherit;
    position: relative;
}

.student-mark-info .configure.filter-active::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(50%, 0%) scale(0);
    background-color: #f24545;
    color: #fff;
    border-radius: 50%;
    width: 10px;
    height: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: showFilter 0.4s 1 forwards;
}

@keyframes showFilter {
    to {
        transform: translate(50%, 0%) scale(1);
    }
}

.student-mark-info .configure img {
    pointer-events: none;
}

.student-mark .configure-filters {
    width: 150px;
    border-radius: 5px;
    border: 0.5px solid #cacaca;
    position: absolute;
    top: 100%;
    right: 0;
    background-color: #fff;
    z-index: 10;
    display: none;
}

.filter-selected {
    background-color: #cacaca !important;
}

.student-mark .configure-filters li {
    height: 35px;
    line-height: 35px;
    font-size: 14px;
    border-bottom: 0.5px solid #cacaca;
    text-align: center;
    cursor: pointer;
}

.student-mark .configure-filters li:hover {
    background-color: #f0f0f0;
}

.student-mark .configure-filters li:last-of-type {
    border-bottom: 0;
}

.student-marks-table {
    margin-top: 15px;
    position: relative;
    min-height: 380px;
}

.student-marks-table::before {
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -200%);
    width: 100%;
    font-size: 20px;
    text-align: center;
}

.student-marks-table .table-heading {
    display: flex;
    justify-content: space-between;
    margin-bottom: 25px;
}

.student-marks-table .table-heading li {
    font-weight: 1000;
    font-size: 16px;
    line-height: 14px;
    color: #686868;
    width: 25%;
    text-align: center;
}

.student-marks-table .table-heading li:first-of-type {
    padding-left: 12px;
    text-align: left;
}

.student-marks-table .table-heading li:last-of-type {
    text-align: right !important;
    padding-right: 18px;
}

.student-marks-table .table-body li {
    background: #ececec;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 18px;
    margin-bottom: 10px;
}

.hide-mark {
    display: none !important;
}

.student-marks-table .table-body li div {
    width: 25%;
    text-align: center;
}

.student-marks-table .table-body li div:first-of-type {
    text-align: left;
}

.student-marks-table .table-body li div:last-of-type {
    text-align: right;
}

.socket {
    width: 200px;
    height: 200px;
    position: absolute;
    left: 50%;
    margin-left: -100px;
    top: 50%;
    margin-top: -100px;
}

.hex-brick {
    background: #abf8ff;
    width: 30px;
    height: 17px;
    position: absolute;
    top: 5px;
    animation-name: fade;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    -webkit-animation-name: fade;
    -webkit-animation-duration: 2s;
    -webkit-animation-iteration-count: infinite;
}

.h2 {
    transform: rotate(60deg);
    -webkit-transform: rotate(60deg);
}

.h3 {
    transform: rotate(-60deg);
    -webkit-transform: rotate(-60deg);
}

.gel {
    height: 30px;
    width: 30px;
    transition: all 0.3s;
    -webkit-transition: all 0.3s;
    position: absolute;
    top: 50%;
    left: 50%;
}

.center-gel {
    margin-left: -15px;
    margin-top: -15px;

    animation-name: pulse;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    -webkit-animation-name: pulse;
    -webkit-animation-duration: 2s;
    -webkit-animation-iteration-count: infinite;
}

.c1 {
    margin-left: -47px;
    margin-top: -15px;
}

.c2 {
    margin-left: -31px;
    margin-top: -43px;
}

.c3 {
    margin-left: 1px;
    margin-top: -43px;
}

.c4 {
    margin-left: 17px;
    margin-top: -15px;
}

.c5 {
    margin-left: -31px;
    margin-top: 13px;
}

.c6 {
    margin-left: 1px;
    margin-top: 13px;
}

.c7 {
    margin-left: -63px;
    margin-top: -43px;
}

.c8 {
    margin-left: 33px;
    margin-top: -43px;
}

.c9 {
    margin-left: -15px;
    margin-top: 41px;
}

.c10 {
    margin-left: -63px;
    margin-top: 13px;
}

.c11 {
    margin-left: 33px;
    margin-top: 13px;
}

.c12 {
    margin-left: -15px;
    margin-top: -71px;
}

.c13 {
    margin-left: -47px;
    margin-top: -71px;
}

.c14 {
    margin-left: 17px;
    margin-top: -71px;
}

.c15 {
    margin-left: -47px;
    margin-top: 41px;
}

.c16 {
    margin-left: 17px;
    margin-top: 41px;
}

.c17 {
    margin-left: -79px;
    margin-top: -15px;
}

.c18 {
    margin-left: 49px;
    margin-top: -15px;
}

.c19 {
    margin-left: -63px;
    margin-top: -99px;
}

.c20 {
    margin-left: 33px;
    margin-top: -99px;
}

.c21 {
    margin-left: 1px;
    margin-top: -99px;
}

.c22 {
    margin-left: -31px;
    margin-top: -99px;
}

.c23 {
    margin-left: -63px;
    margin-top: 69px;
}

.c24 {
    margin-left: 33px;
    margin-top: 69px;
}

.c25 {
    margin-left: 1px;
    margin-top: 69px;
}

.c26 {
    margin-left: -31px;
    margin-top: 69px;
}

.c27 {
    margin-left: -79px;
    margin-top: -15px;
}

.c28 {
    margin-left: -95px;
    margin-top: -43px;
}

.c29 {
    margin-left: -95px;
    margin-top: 13px;
}

.c30 {
    margin-left: 49px;
    margin-top: 41px;
}

.c31 {
    margin-left: -79px;
    margin-top: -71px;
}

.c32 {
    margin-left: -111px;
    margin-top: -15px;
}

.c33 {
    margin-left: 65px;
    margin-top: -43px;
}

.c34 {
    margin-left: 65px;
    margin-top: 13px;
}

.c35 {
    margin-left: -79px;
    margin-top: 41px;
}

.c36 {
    margin-left: 49px;
    margin-top: -71px;
}

.c37 {
    margin-left: 81px;
    margin-top: -15px;
}

.r1 {
    animation-name: pulse;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-delay: 0.2s;
    -webkit-animation-name: pulse;
    -webkit-animation-duration: 2s;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-delay: 0.2s;
}

.r2 {
    animation-name: pulse;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-delay: 0.4s;
    -webkit-animation-name: pulse;
    -webkit-animation-duration: 2s;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-delay: 0.4s;
}

.r3 {
    animation-name: pulse;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-delay: 0.6s;
    -webkit-animation-name: pulse;
    -webkit-animation-duration: 2s;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-delay: 0.6s;
}

.r1>.hex-brick {
    animation-name: fade;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-delay: 0.2s;
    -webkit-animation-name: fade;
    -webkit-animation-duration: 2s;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-delay: 0.2s;
}

.r2>.hex-brick {
    animation-name: fade;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-delay: 0.4s;
    -webkit-animation-name: fade;
    -webkit-animation-duration: 2s;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-delay: 0.4s;
}

.r3>.hex-brick {
    animation-name: fade;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-delay: 0.6s;
    -webkit-animation-name: fade;
    -webkit-animation-duration: 2s;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-delay: 0.6s;
}

.general-loading {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #2eb8cd;
    z-index: 99;
}

@keyframes pulse {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }

    50% {
        -webkit-transform: scale(0.01);
        transform: scale(0.01);
    }

    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

@keyframes fade {
    0% {
        background: #abf8ff;
    }

    50% {
        background: #90bbbf;
    }

    100% {
        background: #abf8ff;
    }
}

@-webkit-keyframes pulse {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }

    50% {
        -webkit-transform: scale(0.01);
        transform: scale(0.01);
    }

    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

@-webkit-keyframes fade {
    0% {
        background: #abf8ff;
    }

    50% {
        background: #389ca6;
    }

    100% {
        background: #abf8ff;
    }
}

.loading-spinner {
    width: 20px;
    height: 20px;
    border: 4px solid #fff;
    border-right-color: transparent;
    border-radius: 50%;
    margin-left: 10px;
    animation: 0.6s loading-spinner infinite;
}

@keyframes loading-spinner {
    to {
        transform: rotate(360deg);
    }
}

button:disabled {
    background-color: #b7b7b7 !important;
    cursor: not-allowed !important;
}

.mark-pagination {
    display: flex;
    gap: 5px;
}

.student-pagination {
    display: flex;
    gap: 5px;
}

.student-pagination li {
    border: 1px solid gray;
    min-width: 25px;
    width: max-content;
    height: 25px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
    padding: 2px;
}

.student-pagination li.active {
    background-color: #129666;
    color: #fff !important;
}

.student-pagination li.disabled {
    background-color: gray !important;
    cursor: not-allowed;
}

.mark-pagination li a {
    border: 1px solid gray;
    width: 25px;
    height: 25px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
}

.mark-pagination li.selected a {
    background-color: #129666;
    color: #fff;
}

.task-modal {
    width: 430px;
    box-shadow: 0px 0px 8px 3px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    z-index: 2;
    height: 450px;
}

.task-modal .task-modal-heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 10px;
}

.task-modal .task-modal-heading ul {
    display: flex;
    position: relative;
    height: 40px;
}

.task-modal-container {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    backdrop-filter: blur(5px) grayscale(100%);
    z-index: 2;
}

.task-modal .task-modal-heading ul .animated-border {
    width: 65px;
    height: 40px;
    border: 1px solid #2eb8cd;
    border-bottom: 0;
    border-radius: 5px 5px 0px 0px;
    position: absolute;
    left: 0;
    top: 0;
    transform: translateX(0);
    pointer-events: none;
    transition: 0.2s;
}

.task-modal .task-modal-heading ul .animated-border::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 6px;
    bottom: 0;
    left: 0;
    background-color: #fff;
    transform: translateY(50%);
}

.task-modal .task-modal-heading ul li {
    width: 65px;
    height: 35px;
    background: #ffffff;
    border-radius: 5px 5px 0px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.task-modal .task-modal-heading ul li img {
    filter: grayscale(100%);
    width: 20px;
    height: 20px;
    pointer-events: none;
}

.task-modal .task-modal-heading ul li.active-icon img {
    filter: none;
}

.task-modal .task-modal-heading .close-task-modal {
    background: #ff8a8a;
    border-radius: 4px;
    cursor: pointer;
    width: 16px;
    height: 16px;
    border: 0;
}

.task-modal .task-modal-heading .close-task-modal img {
    pointer-events: none;
}

.task-modal .task-modal-body {
    padding: 14px 28px 16px 21px;
    border: 1px solid #2eb8cd;
    height: calc(100% - 40px);
    border-radius: 0px 0px 5px 5px;
}

.task-modal .task-modal-body h3 {
    color: #474747;
    text-align: center;
    margin-bottom: 17px;
}

.task-modal .task-modal-body form {
    width: 173px;
    height: 173px;
    position: relative;
    background: rgba(46, 184, 205, 0.08);
    border: 2px dashed #2eb8cd;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.task-modal .task-modal-body input[type="file"] {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
    z-index: 10;
}

.task-modal .task-modal-body form h4 {
    font-size: 17px;
    text-align: center;
    margin-top: 15px;
    margin-bottom: 12px;
}

.task-modal .task-modal-body form label {
    background: #2eb8cd;
    border-radius: 3px;
    color: #fff;
    padding: 4px 10px;
}

.file-progress-bar {
    width: 104px;
    height: 5px;
    background: rgba(46, 184, 205, 0.25);
    border-radius: 3px;
}

.file-progress-bar .bar {
    background: #2eb8cd;
    height: 100%;
    border-radius: 3px;
    transition: 0.8s;
}

.task-uploading-bars li {
    width: 98%;
    max-width: 210px;
    display: flex;
    align-items: center;
    border: 0.5px solid #2eb8cd;
    border-radius: 4px;
    padding: 5px 10px;
    margin-left: auto;
}

.task-uploading-bars li .percentage {
    font-size: 12px;
    margin-top: 3px;
}

.task-uploading-bars .file-percentage img {
    width: 18px;
    height: 18px;
}

.task-uploading-bars li .file-name {
    font-size: 10px;
    word-break: break-all;
    margin-bottom: 5px;
}

.task-uploading-bars li .file-details {
    margin: 0 5px;
}

.task-uploading-bars li .icon img {
    width: 18px;
}

.task-modal-body-inner>div {
    display: flex;
    justify-content: space-between;
    position: relative;
}

.task-modal-body-inner .title-input {
    height: 35px;
    width: 100%;
    border: 1px solid #000;
    border-radius: 5px;
    padding: 5px;
    margin: 5px 0;
}

.task-modal-body-inner textarea {
    width: 100%;
    min-height: 200px;
    resize: none;
    padding: 10px;
    background: rgba(46, 184, 205, 0.08);
    border: 1px dashed #2eb8cd;
    border-radius: 5px;
}

.send-task-btn {
    background: #2eb8cd;
    border-radius: 3px;
    padding: 8px 12px;
    cursor: pointer;
    border: 0;
    color: #fff;
    display: flex;
    align-items: center;
}

.send-task-area {
    padding: 20px 0;
    display: flex;
    justify-content: space-between;
}

.file-percentage {
    text-align: center;
}

.mark-page-container {
    display: flex;
    justify-content: center;
    width: 1300px;
    max-width: 95%;
    margin: 0 auto;
    margin-top: 100px;
}

.top-students {
    width: 35%;
    border-radius: 5px;
    overflow: hidden;
    margin-right: 40px;
    margin-top: 40px;
}

.top-students-heading {
    background: #2eb8cd;
    color: #fff;
    text-align: center;
    padding: 20px;
}

.top-students-heading h1 {
    font-size: 24px;
    line-height: 32px;
}

.top-students-list {
    background: #ececec;
    padding: 32px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
}

.top-students-list li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #fff;
    padding: 12px;
    border-radius: 10px;
    margin-bottom: 24px;
}

.top-students-list li:last-of-type {
    margin-bottom: 0px;
}

.top-students-list li img:not(.crown) {
    width: 48px;
    height: 48px;
    object-fit: cover;
    margin-right: 14px;
    border-radius: 50%;
}

.top-students-list small {
    font-size: 10px;
}

.top-students-list h3 {
    font-size: 16px;
    margin-bottom: 8px;
}

.top-students-list h1 {
    font-size: 32px;
    margin-top: 8px;
    color: #218938;
}

.top-students-list .student-image {
    position: relative;
}

.top-students-list .student-image .crown {
    position: absolute;
    right: 0;
    top: 0;
    transform: translateY(-25%);
}

.edit-profile {
    width: 700px;
    margin: 20px auto;
    border: 2px solid #2eb8cd;
    border-radius: 10px;
    max-width: 95%;
}

.edit-profile .edit-profile-header {
    position: relative;
    z-index: 0;
    display: flex;
    align-items: center;
    padding: 40px 20px;
}

.edit-profile .edit-profile-header::before {
    content: "";
    width: 100%;
    height: 125px;
    background: #2eb8cd;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 5px 5px 0px 0px;
    z-index: -1;
}

.edit-profile .edit-profile-header .edit-photo img {
    width: 125px;
    height: 125px;
    border-radius: 50%;
    object-fit: cover;
    border: 10px solid rgba(255, 255, 255, 0.5);
}

.clear-filter {
    padding: 10px 15px;
    border-radius: 10px;
    background-color: #2eb8cd;
    outline: 0;
    border: 1px solid #fff;
    cursor: pointer;
    color: #fff;
    font-weight: bold;
}

.change-language img:last-of-type {
    margin-right: 0;
}

.swal2-title {
    font-size: 25px !important;
}

.edit-profile .edit-profile-header .edit-details {
    margin-left: 20px;
    color: #fff;
}

.edit-profile .edit-profile-header .edit-details h1 {
    font-size: 30px;
    font-weight: 500;
}

.edit-profile .edit-profile-header .edit-details p {
    font-size: 14px;
    margin: 15px 0;
}

.edit-profile .edit-profile-header .edit-details .photo-operations {
    margin-top: 40px;
}

.edit-profile .edit-profile-header .edit-details .photo-operations button {
    background: #ffffff;
    border: 0.5px solid #c7c7c7;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    padding: 10px 15px;
    cursor: pointer;
    margin-right: 15px;
}

.text-colored {
    color: #2eb8cd;
}

.edit-profile-body {
    padding: 20px 40px;
}

.edit-profile-body h4 {
    font-size: 16px;
    color: #9a9a9a;
    margin-bottom: 20px;
}

.edit-profile-body .row {
    display: flex;
    gap: 20px;
    justify-content: space-between;
}

.edit-profile-body .row .form-group {
    position: relative;
    width: 260px;
}

.edit-profile-body .show_password {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
}

.edit-profile-body .row .form-group>div {
    position: relative;
}

.edit-profile-body .row .form-group input {
    display: block;
    margin-top: 6px;
    width: 100%;
    height: 40px;
    background-color: #fff;
    border: 0.5px solid #c7c7c7;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    padding: 5px;
    padding-right: 30px;
}

.edit-profile-body hr {
    height: 2px;
    border: 0;
    background-color: #cccbcb;
    margin: 20px 0;
}

#saveEditing {
    background-color: #2eb8cd;
    border: 0;
    color: #fff;
    width: 115px;
    height: 35px;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 20px;
    margin-left: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

#saveEditing .loading-spinner {
    width: 15px;
    height: 15px;
}

#update_photo {
    display: none;
}

input:disabled {
    background-color: #c7c7c7 !important;
}

.page-loader-container {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    background-color: white;
    z-index: 999;
}

.page-loader {
    width: 200px;
    height: 200px;
    margin: auto;
    position: relative;
}

.page-loader .item {
    width: 100px;
    height: 100px;
    position: absolute;
}

.page-loader .item-1 {
    background-color: #2eb8cd;
    top: 0;
    left: 0;
    z-index: 1;
    animation: item-1_move 1.8s cubic-bezier(0.6, 0.01, 0.4, 1) infinite;
}

.page-loader .item-2 {
    background-color: #5dc816;
    top: 0;
    right: 0;
    animation: item-2_move 1.8s cubic-bezier(0.6, 0.01, 0.4, 1) infinite;
}

.page-loader .item-3 {
    background-color: #00ddff;
    bottom: 0;
    right: 0;
    z-index: 1;
    animation: item-3_move 1.8s cubic-bezier(0.6, 0.01, 0.4, 1) infinite;
}

.page-loader .item-4 {
    background-color: #66ff00;
    bottom: 0;
    left: 0;
    animation: item-4_move 1.8s cubic-bezier(0.6, 0.01, 0.4, 1) infinite;
}

@keyframes item-1_move {

    0%,
    100% {
        transform: translate(0, 0);
    }

    25% {
        transform: translate(0, 100px);
    }

    50% {
        transform: translate(100px, 100px);
    }

    75% {
        transform: translate(100px, 0);
    }
}

@keyframes item-2_move {

    0%,
    100% {
        transform: translate(0, 0);
    }

    25% {
        transform: translate(-100px, 0);
    }

    50% {
        transform: translate(-100px, 100px);
    }

    75% {
        transform: translate(0, 100px);
    }
}

@keyframes item-3_move {

    0%,
    100% {
        transform: translate(0, 0);
    }

    25% {
        transform: translate(0, -100px);
    }

    50% {
        transform: translate(-100px, -100px);
    }

    75% {
        transform: translate(-100px, 0);
    }
}

@keyframes item-4_move {

    0%,
    100% {
        transform: translate(0, 0);
    }

    25% {
        transform: translate(100px, 0);
    }

    50% {
        transform: translate(100px, -100px);
    }

    75% {
        transform: translate(0, -100px);
    }
}

.user-tasks-section {
    width: 1064px;
    max-width: 95%;
    margin: 32px auto;
}

.task-section-header {
    background: linear-gradient(90deg, #2eb8cd 37.69%, #63b22f 100%);
    border-radius: 5px;
    padding: 100px 0 28px 28px;
    color: #fff;
}

.task-section-header h1 {
    font-weight: 1000;
    font-size: 40px;
    line-height: 34px;
}

.task-section-header p {
    font-size: 24px;
    line-height: 21px;
    margin-top: 8px;
}

.task-section-inner {
    margin-top: 24px;
}

.task-accordion {
    border-bottom: 1px solid #acacac;
    padding: 25px 0 15px 0;
    cursor: pointer;
}

.task-accordion-header {
    font-size: 22px;
}

.task-accordion-header>img {
    margin-left: 15px;
    transition: 0.4s;
}

.task-accordion-body {
    border: 1px solid #acacac;
    border-radius: 5px;
    padding: 10px;
    margin: 15px 0;
    display: flex;
    align-items: center;
}

.task-accordion-body p {
    margin-bottom: 8px;
    font-size: 22px;
    line-height: 25px;
    color: #161616;
}

.task-accordion-body small {
    color: #696969;
}

.task-accordion-body .book-icon,
.task-details-header .book-icon {
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin-right: 15px;
    background: #e8710a;
}

.task-accordion-body .task-name {
    width: calc(100% - 70px);
}

.task-details {
    width: 1064px;
    max-width: 95%;
    margin: 50px auto 0 auto;
}

.task-details-header {
    display: flex;
    align-items: center;
    border-bottom: 2px solid #e8710a;
    padding-bottom: 30px;
}

.task-details-header .task-name {
    margin-left: 20px;
}

.task-details-header .task-name h1 {
    color: #e8710a;
    font-size: 40px;
    line-height: 34px;
    margin-bottom: 15px;
}

.task-details-header .task-name p {
    font-weight: 300;
    font-size: 16px;
    line-height: 14px;
    color: #696969;
}

.task-details-body {
    padding: 25px 0;
}

.task-details-body>p {
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
}

.task-details-body .task-file {
    background-color: #fff;
    width: 373px;
    height: 77px;
    display: flex;
    align-items: center;
    position: relative;
    border: 1px solid #acacac;
    border-radius: 10px;
    margin-top: 40px;
}

.task-details-body .task-file .download-task {
    position: absolute;
    top: 50%;
    right: -15px;
    transform: translate(100%, -50%);
}

.task-details-body .task-file .icon {
    width: 92px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.back-to-tasks {
    transform: rotate(90deg);
    margin-right: 10px;
    cursor: pointer;
}

.back-to-tasks:hover {
    -webkit-animation: backroute 0.9s both;
    animation: backroute 0.9s both;
}

@-webkit-keyframes backroute {
    0% {
        -webkit-transform: scale3d(1, 1, 1) rotate(90deg);
        transform: scale3d(1, 1, 1) rotate(90deg);
    }

    30% {
        -webkit-transform: scale3d(1.25, 0.75, 1) rotate(90deg);
        transform: scale3d(1.25, 0.75, 1) rotate(90deg);
    }

    40% {
        -webkit-transform: scale3d(0.75, 1.25, 1) rotate(90deg);
        transform: scale3d(0.75, 1.25, 1) rotate(90deg);
    }

    50% {
        -webkit-transform: scale3d(1.15, 0.85, 1) rotate(90deg);
        transform: scale3d(1.15, 0.85, 1) rotate(90deg);
    }

    65% {
        -webkit-transform: scale3d(0.95, 1.05, 1) rotate(90deg);
        transform: scale3d(0.95, 1.05, 1) rotate(90deg);
    }

    75% {
        -webkit-transform: scale3d(1.05, 0.95, 1) rotate(90deg);
        transform: scale3d(1.05, 0.95, 1) rotate(90deg);
    }

    100% {
        -webkit-transform: scale3d(1, 1, 1) rotate(90deg);
        transform: scale3d(1, 1, 1) rotate(90deg);
    }
}

@keyframes backroute {
    0% {
        -webkit-transform: scale3d(1, 1, 1) rotate(90deg);
        transform: scale3d(1, 1, 1) rotate(90deg);
    }

    30% {
        -webkit-transform: scale3d(1.25, 0.75, 1) rotate(90deg);
        transform: scale3d(1.25, 0.75, 1) rotate(90deg);
    }

    40% {
        -webkit-transform: scale3d(0.75, 1.25, 1) rotate(90deg);
        transform: scale3d(0.75, 1.25, 1) rotate(90deg);
    }

    50% {
        -webkit-transform: scale3d(1.15, 0.85, 1) rotate(90deg);
        transform: scale3d(1.15, 0.85, 1) rotate(90deg);
    }

    65% {
        -webkit-transform: scale3d(0.95, 1.05, 1) rotate(90deg);
        transform: scale3d(0.95, 1.05, 1) rotate(90deg);
    }

    75% {
        -webkit-transform: scale3d(1.05, 0.95, 1) rotate(90deg);
        transform: scale3d(1.05, 0.95, 1) rotate(90deg);
    }

    100% {
        -webkit-transform: scale3d(1, 1, 1) rotate(90deg);
        transform: scale3d(1, 1, 1) rotate(90deg);
    }
}

.task-details-body .task-file .task-file-details * {
    font-weight: 400;
    font-size: 16px;
    line-height: 14px;
    margin-bottom: 8px;
}

.instructors-container {
    width: 1200px;
    max-width: 90%;
    margin: 20px auto;
}

.instructors-container>h1 {
    text-align: center;
    margin: 20px;
}

.instructors-inner {
    background: #ffffff;
    border: 2px solid #2eb8cd;
    border-radius: 10px;
    padding: 20px;
}

.instructors-heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 30px;
}

.add-instructor {
    border: 0;
    background: #2eb8cd;
    border-radius: 3px;
    color: #fff;
    padding: 10px 15px;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.add-instructor img {
    margin-left: 10px;
}

.instructor-search {
    width: 215px;
    height: 35px;
    border: 1px solid #a6a6a6;
    position: relative;
    border-radius: 3px 0px 0px 3px;
}

.instructor-search input {
    width: 100%;
    height: 100%;
    border: 0;
    background-color: transparent;
    padding: 0 20px;
    border-radius: 3px 0px 0px 3px;
}

.instructor-search button {
    width: 35px;
    height: 35px;
    background: #2eb8cd;
    transform: translateX(100%);
    border: 0;
    position: absolute;
    top: -1px;
    right: 0;
    cursor: pointer;
    border-radius: 0px 3px 3px 0px;
}

.instructors-table-heading {
    display: flex;
    padding: 20px 10px;
    color: #686868;
    font-weight: bold;
}

.instructors-table .mark-pagination {
    margin-top: 10px;
}

.instructors-table-heading div {
    flex: 2;
}

.instructors-table-heading div:last-of-type,
.instructors-table-heading div:first-of-type {
    flex: 1;
}

.instructors-table-item {
    display: flex;
    align-items: center;
    background: #ececec;
    border-radius: 5px;
    padding: 5px;
    margin-bottom: 5px;
}

.instructors-table-body {
    min-height: 540px;
}

.instructors-table-item div {
    flex: 2;
    word-break: break-all;
}

.instructors-table-item div:last-of-type,
.instructors-table-item div:first-of-type {
    flex: 1;
}

.instructors-table-item .instructor-crud {
    display: flex;
    align-items: center;
}

.instructors-table-item .instructor-crud button,
.instructors-table-item .instructor-crud a {
    border-radius: 2px;
    border: 0;
    width: 30px;
    height: 30px;
    cursor: pointer;
    display: inline-block;
    display: flex;
    align-items: center;
    justify-content: center;
}

.instructors-table-item .edit {
    background: #48bb20;
    margin-right: 10px;
}

.instructors-table-item .see-group {
    background: #00ddff;
    margin-right: 10px;
}

.instructors-table-item .see-group img {
    filter: brightness(200%);
}

.instructors-table-item .trash {
    background: #c85656;
}

.instructor-list-photo {
    width: 35px;
    height: 35px;
    object-fit: cover;
    border-radius: 50%;
    transition: 0.3s;
}

.instructors-table-item:hover .instructor-list-photo {
    transform: scale(1.3);
}

.instructor-groups-toggle {
    background-color: #2eb8cd;
    color: #fff;
    font-weight: bold;
    display: flex;
    align-items: center;
    border: 0;
    padding: 5px 10px;
    border-radius: 5px;
    display: inline-block;
    cursor: pointer;
}

.instructor-groups-toggle img {
    width: 10px;
    margin-left: 5px;
    transition: 0.3s;
}

.instructors-table-item .group-dropdown {
    width: max-content;
    margin-top: 10px;
    background-color: #fff;
    padding: 10px;
    border-radius: 5px;
    z-index: 9;
    position: absolute;
    transition: 0.15s;
}

.instructors-table-item .group-dropdown a {
    color: #000;
    font-weight: bold;
}

.instructors-table-item .group-dropdown li {
    margin-bottom: 8px;
}

.instructor-groups-cell {
    position: relative;
}

.add-modal-container {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    flex-direction: column;
    backdrop-filter: blur(10px);
    z-index: 99;
}

.add-modal-heading {
    margin-top: 100px;
    margin-bottom: 50px;
    font-size: 32px;
    text-align: center;
}

.add-modal-container form {
    width: 1000px;
    max-width: 100%;
    background-color: #fff;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 60px 20px 40px 20px;
    border-radius: 5px;
    border: 1px solid #00ddff;
    position: relative;
}

.add-modal-container form .new-group-name {
    position: absolute;
    top: 15px;
    text-align: center;
    width: 100%;
}

.add-modal-container form .form-group {
    width: 48%;
    margin-bottom: 25px;
}

.add-modal-container form .form-group.w-100 {
    width: 100%;
}

.add-modal-container form .form-group input:not([type="checkbox"]),
.add-modal-container form .form-group select {
    display: block;
    width: 100%;
    height: 35px;
    margin-top: 8px;
    margin-bottom: 5px;
    padding: 5px;
    border-radius: 5px;
    border: 1px solid black;
}

.add-modal-container form button {
    padding: 20px 0 !important;
    margin-top: 20px;
}

.individual-lesson-time {
    width: 100%;
    border-top: 1px solid #bebebe;
    padding-top: 30px;
}

.time-element {
    width: 92px;
    height: 48px;
    border-radius: 46px;
    color: #c7c7c7;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #c7c7c7;
    text-align: center;
    font-size: 16px;
    margin-bottom: 9px;
    transition: 0.2s;
}

div.time-element {
    cursor: pointer;
}

input.time-element {
    color: #2eb8cd;
    outline: 0;
}

.time-element::placeholder {
    color: #c7c7c7;
}

.individual-lesson-time>p {
    margin-bottom: 10px;
}

.time-element.active {
    background: #2eb8cd;
    color: #fff !important;
    border-color: #fff;
}

.time-row {
    display: flex;
}

.time-col {
    flex: 1;
}

.headerNavigation {
    max-width: 900px;
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
    gap: 30px;
    position: absolute;
    top: 100%;
    z-index: 9;
    background: #ffffff;
    border: 1px solid #dbdbdb;
    box-shadow: 0px 0px 7px 1px rgba(0, 0, 0, 0.08);
    border-radius: 5px;
    padding: 40px;
}

.headerNavigation li {
    width: 80px;
    text-align: center;
}

.headerNavigation li a {
    width: 100%;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    font-size: 20px;
    font-weight: 300;
    color: #000;
}

.headerNavigation li a .nav-icon {
    border: 1px solid transparent;
    width: 100%;
    height: 80px;
    background: linear-gradient(30.97deg, #2eb8cd 0%, #63b22f 126.14%);
    box-shadow: 8px 8px 10px -5px rgb(0 27 31 / 25%);
    border-radius: 26px;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.3s;
}

.headerNavigation li a .nav-icon img {
    width: 45px;
    height: 45px;
}

.headerNavigation-container>p {
    font-weight: 400;
    font-size: 24px;
    line-height: 21px;
    color: #262626;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.headerNavigation-container {
    margin-left: 15px;
}

.headerNavigation-container>p img {
    margin-left: 15px;
    transition: 0.3s;
}

.loginLogo {
    height: 75px;
}

header .logo {
    display: flex;
    align-items: center;
}

.login-social-media {
    display: none;
}

.login-social-media img {
    filter: invert(67%) sepia(58%) saturate(570%) hue-rotate(140deg) brightness(85%) contrast(87%);
}

.forgot-password-container {
    width: 600px;
    max-width: 95%;
    margin: 100px auto;
    padding: 30px;
    border: 2px solid #2eb8cd;
    border-radius: 5px;
    text-align: center;
}

.forgot-password-container .search-area {
    position: relative;
    width: 450px;
    max-width: 95%;
    margin: 0 auto;
}

.forgot-password-container .search-area input {
    height: 35px;
    background: #ffffff;
    border: 0.5px solid #c7c7c7;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    margin: 15px 0;
    padding: 5px;
    width: 350px;
}

.forgot-password-container .search-area .search-form {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.forgot-password-container .search-area button {
    width: auto;
    padding: 10px 15px;
}

.forgot-password-container .search-area ul {
    background-color: #fff;
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
}

.forgot-password-container .search-area ul li {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-bottom: 10px;
}

.forgot-password-container .search-area ul li:last-of-type {
    margin-bottom: 0;
}

.forgot-password-container .search-area ul li img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 15px;
}

.group-many-instructor {
    margin-bottom: 8px;
}

.group-many-instructor:last-of-type {
    margin-bottom: 0;
}

.group-details {
    width: 1440px;
    max-width: 95%;
    margin: 20px auto;
    padding: 20px;
}

.group-details-header {
    text-align: center;
    font-size: 26px;
    color: #2eb8cd;
}

.group-details .group-information {
    display: flex;
    gap: 15px;
    margin: 20px 0;
}

.group-details-user {
    flex: 4;
}

.group-details-user .group-details-user-heading {
    width: 900px;
}

.group-details-user .group-details-user-heading .row {
    display: flex;
    width: 900px;
    font-weight: bold;
    padding: 10px;
}

.group-details-user .group-details-user-heading .row>div {
    flex: 3;
    text-align: center;
}

.group-details-user .group-details-user-heading .row>div:first-of-type,
.group-details-user .group-details-user-body .row>div:first-of-type {
    text-align: left;
    flex: 2;
}

.group-details-user .group-details-user-heading .row>div:last-of-type,
.group-details-user .group-details-user-body .row>div:last-of-type {
    text-align: right;
    flex: 1;
}

.group-details-user .group-details-user-body {
    width: 900px;
}

.group-details-user .group-details-user-body .row {
    display: flex;
    width: 900px;
    gap: 15px;
    word-break: break-all;
    border-radius: 5px;
    background: #ececec;
    padding: 0 10px;
    height: 30px;
    margin-bottom: 16px;
    align-items: center;
}

.group-details-user .group-details-user-body .row>div {
    flex: 3;
    text-align: center;
}

.row.instructor-row {
    background: #2eb8cd !important;
    color: #fff !important;
}

.no-class-name {
    display: flex;
}

.group-weekly-marks {
    flex: 2;
}

.group-weekly-marks-heading {
    display: flex;
    gap: 8px;
    margin-top: 33px;
}

.group-weekly-marks-heading>div,
.group-weekly-marks-body>.row>div {
    width: 45px;
    height: 30px;
    background: #f4f4f4;
    border: 1px solid #c7c7c7;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.group-weekly-marks-body>.row {
    display: flex;
    gap: 8px;
    margin-bottom: 16px;
}

.group-weekly-marks-body {
    margin-top: 16px;
}

.group-detail-info-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.group-detail-info-header .group-info-datepicker {
    width: 300px;
    display: flex;
    align-items: center;
    gap: 10px;
}

.group-detail-info-header .group-info-datepicker input {
    width: 120px;
}

.mentor-icon {
    margin-right: 10px;
    background-color: #00ddff;
    font-weight: 1000;
    color: #fff;
}

.mentor-modal .add-modal-body {
    text-align: center;
}

.mentor-modal form {
    background-color: transparent;
    width: 100%;
    max-width: 100%;
    border: 0;
    justify-content: center !important;
}

.mentor-modal .forgot-password-container .search-area {
    width: 100%;
    max-width: 100%;
}

.mentor-modal .forgot-password-container {
    width: 700px;
    max-width: 100%;
    background-color: #fff;
    padding: 50px;
}

.mentor-modal .search-area form button,
.mentor-modal .search-area form input {
    margin: 0;
    outline: 0;
}

.mentor-modal .search-area form button {
    padding: 20px 10px !important;
}

.mentor-modal .search-area form input {
    height: 56px;
    margin-right: 15px;
}

.mentor-modal .forgot-password-container .search-area ul li {
    text-align: left;
}

.mentor-modal .forgot-password-container .search-area ul li p {
    margin-top: 5px;
}

.mentor-modal .mentor-disabled {
    filter: grayscale(90%);
    cursor: not-allowed !important;
}

.not_found_404 {
    position: relative;
    height: 100vh;
    z-index: 0;
}

.not_found_404 img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}

.not_found_404 h1 {
    color: #2ab7cd;
    position: absolute;
    right: 30px;
    top: 30px;
    font-size: 152px;
}

.not_found_404 div {
    width: 80%;
    position: absolute;
    left: 50%;
    bottom: 100px;
    transform: translateX(-50%);
    text-align: center;
}

.not_found_404 .btn {
    padding: 20px;
    width: max-content;
    margin: 0 auto;
}

.not_found_404 p {
    width: max-content;
    max-width: 90%;
    color: #2ab7cd;
    font-size: 24px;
    background-color: #fff;
    padding: 5px;
    margin: 40px auto 0 auto;
}

.mentor-page {
    width: 800px;
    max-width: 90%;
    margin: 50px auto;
    border: 2px solid #00ddff;
    border-radius: 10px;
    padding: 10px;
}

.mentor-page-heading {
    text-align: center;
    padding: 20px 0;
}

.mentor-page .mentor-page-table-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #ececec;
    border-radius: 5px;
    padding: 12px;
    margin: 15px 0;
}

.mentor-page .mentor-page-table-row .operations button {
    border: 0;
    width: 25px;
    height: 25px;
    background: #a0a0a0;
    border-radius: 2px;
    cursor: pointer;
}

.mentor-page .mentor-page-table-row .operations button.plus {
    margin-right: 10px;
}

.mentor-page .mentor-page-table-row .operations button.plus.selected {
    background-color: #00ddff;
}

.mentor-page .mentor-page-table-row .operations button.plus.absent {
    background-color: #f24545;
}

.mentor-page .mentor-page-table-row .operations button img {
    width: 10px;
    height: 10px;
}

.mentor-page .mentor-submit {
    padding: 10px 15px;
    border-radius: 5px;
    color: #fff;
    border: 0;
    margin-left: auto;
    cursor: pointer;
    display: block;
    margin-top: 25px;
    background-color: #00ddff;
}

.mentor-page .mentor-submit:disabled {
    background: #b7b7b7 !important;
}

.support-modal-container {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9;
    backdrop-filter: blur(6px);
    display: flex;
    justify-content: center;
    align-items: center;
}

.support-modal-container form {
    background-color: #fff;
    width: 700px;
    max-width: 95%;
    border-radius: 10px;
    border: 2px solid #2eb8cd;
}

.support-modal-container .form-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 25px;
    flex-wrap: wrap;
}

.support-modal-container form {
    padding: 22px;
}

.support-modal-container form label {
    width: 100%;
    display: block;
}

.support-modal-container form .cat_label {
    width: 162px;
    max-width: 30%;
}

.support-modal-container form .title_label {
    width: 417px;
    max-width: 65%;
}

.support-modal-container form select {
    width: 100%;
    height: 42px;
    display: block;
    border: 1px solid #a6a6a6;
    outline: 0;
    margin-top: 10px;
    border-radius: 5px;
}

.support-modal-container form input {
    width: 100%;
    height: 42px;
    border: 1px solid #a6a6a6;
    display: block;
    outline: 0;
    padding: 5px;
    margin-top: 10px;
    border-radius: 5px;
}

.support-modal-container form textarea {
    width: 100%;
    resize: vertical;
    min-height: 200px;
    max-height: 450px;
    border: 1px solid #a6a6a6;
    outline: 0;
    padding: 5px;
    margin-top: 10px;
    border-radius: 5px;
    line-height: 1;
    font-size: 14px;
}

.support-modal-container form .btn {
    width: max-content;
    margin-left: auto;
    padding: 10px;
}

.support-modal-container .text-danger {
    margin-top: 5px;
    display: inline-block;
}

.unread-message-count {
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(50%, -50%);
    background-color: #fd1014;
    color: #fff;
    font-size: 16px;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.read-message {
    background-color: transparent;
    border-radius: 5px !important;
    border: 1px solid rgba(0, 0, 0, 0.5) !important;
    width: 20px !important;
    height: 20px !important;
}

.read-message img {
    width: 65%;
    height: 65%;
}

.toggle-show-password {
    position: absolute;
    left: unset !important;
    top: 50% !important;
    right: 15px !important;
    transform: translateY(-50%) !important;
    z-index: 2;
    cursor: pointer;
}

.coders-club {
    width: 100%;
    height: 80vh;
    background: linear-gradient(90deg, #2eb8cd 37.69%, #63b22f 100%);
    position: relative;
    z-index: 0;
}

.coders-club::before {
    content: "";
    position: absolute;
    width: 110%;
    height: 40%;
    background-color: #fff;
    bottom: 0;
    right: 0;
    clip-path: polygon(101% 0, 0% 101%, 101% 101%);
    z-index: -1;
}

.coders-club-inner {
    width: 60%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 60px;
}

.coders-club-inner .club-logo {
    width: 416px;
    height: 146px;
}

.coders-club-inner a {
    background-color: #e0fbff;
    color: #0c7b8c;
    display: inline-block;
    padding: 16px 24px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.18);
    border-radius: 8px;
}

.coders-club-persons-mobile {
    display: none;
}

.coders-club-inner p {
    margin: 40px 0;
    max-width: 90%;
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    color: #fff;
}

.coders-club-persons {
    position: absolute;
    right: 10px;
    top: 20px;
    z-index: -1;
    transform: scale(0.85);
}

.form-element {
    width: 150px;
    display: inline-block;
    color: #000;
}

.form-element.create p {
    background: linear-gradient(63.34deg, #2eb8cd 22.62%, #63b22f 106.2%);
    font-size: 90px;
}

.form-element .form-element-data {
    width: 100%;
    height: 187px;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
}

.admin-forum {
    width: calc(100% - 376px);
    margin: 20px auto;
    max-width: 95%;
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    border-top: 3px solid #dfdfdf;
    padding-top: 20px;
}

.form-element h4 {
    font-size: 16px;
    margin-top: 12px;
}

.custom-switch {
    width: 37px;
    height: 14px;
    border-radius: 14px;
    position: relative;
    background-color: #b9b9b9;
    cursor: pointer;
}

.custom-switch::before {
    content: "";
    width: 20px;
    height: 20px;
    position: absolute;
    top: 50%;
    left: 0%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    border: 1px solid #b9b9b9;
    border-radius: 50%;
    transition: 0.2s;
}

.custom-switch.active::before {
    left: 100%;
    background-color: #00ddff;
    border-color: #fff;
}

.custom-switch.active {
    background: linear-gradient(63.34deg, #2eb8cd 22.62%, #63b22f 106.2%);
}

.new-form {
    width: 800px;
    max-width: 95%;
    margin: 20px auto;
    padding-bottom: 50px;
}

.new-form-heading {
    background-color: #fff;
    display: flex;
    padding: 15px;
    flex-direction: column;
    box-shadow: 10px 10px 15px rgba(0, 0, 0, 0.05);
    border-top: 10px solid #00ddff;
    border-radius: 5px;
}

.new-form-heading .forum-title {
    height: 48px;
    border: 0;
    border-bottom: 1px solid gray;
    font-size: 26px;
}

.new-form-heading .forum-body {
    height: 21px;
    border: 0;
    border-bottom: 1px solid gray;
    margin-top: 15px;
}

.new-form input {
    outline: 0;
}

.new-form-item {
    background-color: #fff;
    margin: 15px 0;
    box-shadow: 0 0 15px 3px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    padding: 15px;
}

.new-form-item-heading {
    display: flex;
    gap: 20px;
}

.new-form-item-heading .input-group {
    flex: 5;
}

.new-form-item-heading input {
    height: 30px;
    font-size: 16px;
    padding: 5px;
    width: 100%;
}

.new-form-item-heading select {
    height: 30px;
    width: 100%;
}

.new-form-item-heading .select-group {
    flex: 1;
}

.new-form-item-footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.new-form-item-footer .trash {
    filter: brightness(0);
    width: 20px;
    height: 20px;
    cursor: pointer;
}

.new-form-item-footer .required-or-not {
    display: flex;
    cursor: pointer;
}

.new-form-item-footer .remove-question {
    position: relative;
    padding-right: 20px;
}

.new-form-item-footer .remove-question::before {
    content: "";
    width: 1px;
    height: 100%;
    background-color: #000;
    position: absolute;
    top: 0;
    right: 10px;
}

.new-form-item-footer .required-or-not div {
    margin-left: 20px;
}

.new-form-item-body {
    padding: 20px 0;
}

.new-form-item-body input {
    width: 100%;
    padding: 5px;
    height: 35px;
    border: 1px solid #000;
    border-radius: 5px;
}

.new-form-item-body li {
    margin: 10px 0;
    display: flex;
    position: relative;
}

.new-form-item-body li .remove-answer {
    font-size: 18px;
    color: #fd1014;
    font-weight: bold;
    margin: 0;
    margin-left: 5px;
}

.new-form-item-body button {
    border: 0;
    padding: 10px 18px;
    border-radius: 5px;
    background-color: #fafafa;
    cursor: pointer;
    margin: 5px;
}

.new-form .add-question,
.new-form .save-forum {
    border: 0;
    padding: 0 20px;
    height: 35px;
    border-radius: 5px;
    cursor: pointer;
    border: 1px solid #00ddff;
    margin-left: auto;
    display: block;
    transition: 0.2s;
    margin-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.new-form .save-forum .loading-spinner {
    margin-left: 0;
}

.new-form .add-question:not(:disabled):hover,
.new-form .save-forum:not(:disabled):hover {
    background-color: #00ddff;
    color: #fff;
}

.new-form .text-danger {
    margin-top: 5px;
    font-size: 14px;
    display: block;
}

.new-form li .add-something {
    background-color: #00ddff;
    color: #fff;
}

main.home {
    min-height: calc(100vh - 60px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 50px;
    padding: 15px 0;
}

.send-forum.btn {
    width: max-content;
    display: flex;
    padding: 18px;
    margin: 20px auto;
}

.send-forum.btn img {
    margin-left: 10px;
}

.send-forum-modal-container {
    width: 100%;
    height: 100vh;
    backdrop-filter: blur(3px);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    overflow: auto;
}

.send-forum-modal {
    width: 900px;
    padding: 35px 0 20px 0;
    background: #ffffff;
    border: 1px solid #2eb8cd;
    border-radius: 10px;
    margin-top: 100px;
}

.send-forum-modal .send-forum-body {
    display: flex;
    position: relative;
}

.send-forum-modal .half {
    flex: 1;
    padding: 0 50px;
}

.send-forum-modal .half h3 {
    text-align: center;
}

.send-forum-modal .send-forum-body::before {
    content: "";
    width: 2px;
    height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #00ddff;
}

.send-forum-modal .search-in {
    position: relative;
    border: 1px solid #c9c9c9;
    border-radius: 3px;
    height: 40px;
    margin-top: 30px;
    margin-bottom: 15px;
}

.send-forum-modal .search-in input {
    border: 0;
    width: 100%;
    height: 100%;
    padding-left: 10px;
    padding-right: 40px;
    outline: 0;
}

.send-forum-modal .search-in img {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    filter: brightness(50%);
}

.send-forum-modal ul {
    max-height: 350px;
    overflow-y: auto;
    padding: 5px;
}

.send-forum-modal ul li {
    display: block;
    box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.11);
    border-radius: 3px;
    margin-bottom: 15px;
}

.send-forum-modal ul li:last-of-type {
    margin-bottom: 0;
}

.send-forum-modal ul li label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 16px;
    cursor: pointer;
}

.send-forum-modal .btn {
    width: max-content;
    margin: 25px auto 0 auto;
    padding: 12px 16px;
}

.forum-title {
    border: 1px solid #0169c2;
    border-radius: 10px;
    margin: 17px 0;
    padding: 40px 50px;
    text-align: center;
    position: relative;
    overflow: hidden;
    color: #3d3d3d;
}

.forum-title h2 {
    font-weight: 400;
    font-size: 32px;
    line-height: 27px;
}

.forum-title::before {
    content: "";
    width: 100%;
    height: 16px;
    position: absolute;
    top: 0;
    left: 0;
    background: linear-gradient(90deg, #0169c2 0%, #7ed4ef 100%);
    border-radius: 10px 10px 0px 0px;
    transform: scale(1.1);
}

.forum-details-heading {
    background: linear-gradient(90deg, #0169c2 0%, #7ed4ef 100%);
}

.forum-question {
    border: 0.5px solid #0169c2;
    border-radius: 10px;
    padding: 34px 44px;
    margin-bottom: 16px;
}

.forum-question h3 {
    font-weight: 300;
    font-size: 22px;
    line-height: 19px;
    color: #3d3d3d;
}

.forum-question ul {
    margin-top: 18px;
}

.forum-question ul li p {
    margin-left: 11px;
}

.forum-question ul li {
    margin-bottom: 18px;
}

.forum-question ul li:last-of-type {
    margin-bottom: 0;
}

.forum-question ul label {
    font-weight: 300;
    font-size: 22px;
    line-height: 19px;
    color: #3d3d3d;
    display: flex;
    align-items: center;
}

.btn.ans-forum {
    background: #0169c2;
    margin-left: auto;
    width: max-content;
    padding: 8px 12px;
}

input.custom-answer {
    width: 100%;
    background-color: inherit;
    border: 0;
    border-bottom: 2px solid #dad9d9;
    margin-top: 18px;
    display: block;
    outline: 0;
    height: 35px;
}

.required-question {
    color: #fd1014;
    margin-left: 5px;
}

.btn.show_form_results {
    padding: 15px 0;
    margin: 10px 0;
}

.select-result-by-group {
    width: 100%;
    height: 40px;
    margin: 10px 0;
}

.results-container {
    display: flex;
}

.results-container .result {
    flex: 1;
    border: 1px solid #000;
}

.results-container .question-title {
    font-size: 12px;
}

.new-form.results {
    width: 95%;
}

.result-time {
    color: gray;
    font-size: 10px;
}

.result-answer p {
    font-size: 12px;
    word-break: break-all;
    line-height: 14px;
}

.result-answer div {
    border-top: 1px solid #000;
    border-bottom: 1px solid #000;
}

.result-answer:last-of-type div {
    border-bottom: none;
    padding: 3px;
}

.result-question {
    padding: 3px;
    height: 30px;
    max-height: max-content;
}

.user-information {
    width: 95%;
    max-width: 1440px;
    margin: 30px auto;
}

.user-information>h1 {
    color: #2eb8cd;
    font-weight: 500;
    font-size: 32px;
    line-height: 27px;
    text-align: center;
    margin-bottom: 40px;
}

.user-information .user-photo {
    width: 160px;
    height: 160px;
    border-radius: 50%;
    border: 11px solid rgba(46, 184, 205, 0.5);
    object-fit: cover;
}

.user-social-medias {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.user-information-inner {
    display: flex;
    gap: 15px;
}

.user-information-inner>div {
    border: 2px solid #2eb8cd;
    border-radius: 10px;
    flex: 1;
    padding: 40px 30px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
}

.user-social-medias a {
    padding: 10px 15px;
    background: #2eb8cd;
    border-radius: 3px;
    display: inline-block;
    color: #fff;
}

.disabled-profile {
    padding: 10px 15px;
    background: #888888;
    border-radius: 3px;
    display: inline-block;
    color: #fff;
    cursor: not-allowed;
}

.user-information .colored {
    font-weight: 400;
    font-size: 22px;
}

.user-information h3 {
    font-size: 30px;
}

.user-information .date-pickers {
    display: flex;
    border: 1px solid #b0b0b0;
    border-radius: 10px;
    background-color: #fff;
}

.user-information .date-picker {
    flex: 1;
    position: relative;
    height: 40px;
    z-index: 0;
}

.user-information .date-picker:first-of-type {
    border-right: 1px solid #b0b0b0;
}

.user-information .date-picker::before {
    content: "";
    width: 20px;
    height: 22px;
    background-image: url("../../assets/icons/calendar.svg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    top: 50%;
    left: 5px;
    transform: translateY(-50%);
    z-index: 1;
    pointer-events: none;
}

.user-information .date-picker input {
    border-radius: 10px 0px 0px 10px;
    padding-left: 40px;
}

.user-information .date-picker input,
.user-information .date-picker .react-datepicker-wrapper,
.user-information .date-picker .react-datepicker__input-container {
    width: 100%;
    height: 100%;
    border: 0;
}

.user-information .date-pickers>button {
    background: #2eb8cd;
    border-radius: 0px 10px 10px 0px;
    border: 0;
    width: 44px;
    height: 40px;
    cursor: pointer;
}

.user-information .user-marks {
    width: 100%;
    max-height: 385px;
    text-align: left;
    overflow: auto;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-right: 10px;
}

.user-information .user-marks::-webkit-scrollbar {
    width: 6px;
}

.user-information .user-marks li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #ececec;
    border-radius: 5px;
    padding: 5px 15px;
    font-size: 14px;
}

.user-information .user-marks li span {
    background-color: #fff;
    width: 54px;
    height: 19px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
}

.user-information .student-mark-progress-container {
    margin-top: 100px;
}

.user-information .operations button {
    font-weight: 1000;
    font-size: 16px;
    line-height: 14px;
    padding: 12px 32px;
    border: 0;
    background: #e0e0e0;
    color: #fff;
    cursor: pointer;
    outline: 0;
}

.user-information .operations button:first-of-type {
    border-radius: 12px 0px 0px 12px;
    margin-right: 5px;
}

.user-information .operations button:last-of-type {
    border-radius: 0px 12px 12px 0px;
}

.user-details-link {
    color: #000;
}

.user-information .date-pickers>button img {
    transform: scale(2);
}

.data-loader {
    transform: rotateZ(45deg) translate(-50%, -50%);
    perspective: 1000px;
    border-radius: 50%;
    width: 48px;
    height: 48px;
    color: #2eb8cd;
    position: absolute;
    top: 50%;
    left: 50%;
}

.data-loader:before,
.data-loader:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: inherit;
    height: inherit;
    border-radius: 50%;
    transform: rotateX(70deg);
    animation: 1s spin linear infinite;
}

.data-loader:after {
    color: #63b22f;
    transform: rotateY(70deg);
    animation-delay: 0.4s;
}

@keyframes rotate {
    0% {
        transform: translate(-50%, -50%) rotateZ(0deg);
    }

    100% {
        transform: translate(-50%, -50%) rotateZ(360deg);
    }
}

@keyframes rotateccw {
    0% {
        transform: translate(-50%, -50%) rotate(0deg);
    }

    100% {
        transform: translate(-50%, -50%) rotate(-360deg);
    }
}

@keyframes spin {

    0%,
    100% {
        box-shadow: 0.2em 0px 0 0px currentcolor;
    }

    12% {
        box-shadow: 0.2em 0.2em 0 0 currentcolor;
    }

    25% {
        box-shadow: 0 0.2em 0 0px currentcolor;
    }

    37% {
        box-shadow: -0.2em 0.2em 0 0 currentcolor;
    }

    50% {
        box-shadow: -0.2em 0 0 0 currentcolor;
    }

    62% {
        box-shadow: -0.2em -0.2em 0 0 currentcolor;
    }

    75% {
        box-shadow: 0px -0.2em 0 0 currentcolor;
    }

    87% {
        box-shadow: 0.2em -0.2em 0 0 currentcolor;
    }
}

.activity-log {
    width: 95%;
    max-width: 1400px;
    margin: 10px auto;
    text-align: center;
}

.activity-log table {
    width: 100%;
    text-align: left;
}

.activity-log table,
.activity-log th,
.activity-log td {
    border: 1px solid #000;
    border-collapse: collapse;
    padding: 5px;
    margin-top: 10px;
}

.input-icon {
    background: #2eb8cd;
    position: absolute;
    top: 0;
    left: 0;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px 0px 0px 3px;
}

.input-with-icon {
    position: relative;
}

.input-with-icon input {
    padding-left: 45px !important;
    outline: none;
}

.happy_birthday_container {
    position: fixed;
    width: 100%;
    height: calc(100vh - 60px);
    top: 60px;
    left: 0;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
}

.all_hbd {
    align-items: flex-start;
}

.happy_birthdays_inner {
    text-align: center;
    width: 650px;
}

.happy_birthday_inner {
    width: 1130px;
    max-width: 90%;
    height: 100%;
    min-height: 400px;
    padding: 20px;
    position: relative;
    z-index: 0;
}

#hbd_overlay {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
}

.happy_birthday_inner h1 {
    color: #00aade;
    font-size: 60px;
}

.happy_birthday_inner p {
    font-size: 20px;
    color: #006ba8;
    margin-top: 20px;
}

.happy_birthday_container button {
    border: 2px solid #cccccc;
    color: #cccccc;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    font-size: 50px;
    cursor: pointer;
    position: absolute;
    top: 20px;
    right: 20px;
    transform: translateX(-50%);
}

.happy_birthdays_inner ul {
    background-color: #fff;
    display: flex;
    gap: 16px;
    width: 100%;
    text-align: left;
    padding: 16px;
    padding-top: 40px;
    box-shadow: 30px 30px 25px -8px rgba(0, 0, 0, 0.23);
    border-radius: 5px;
    flex-wrap: wrap;
}

#hbd_text {
    transform: translateY(35px);
}

.happy_birthdays_inner ul li {
    display: flex;
    gap: 8px;
    width: 48%;
    align-items: center;
    border: 0.5px solid #2eb8cd;
    box-shadow: 8px 8px 10px -2px rgba(46, 184, 205, 0.14);
    border-radius: 10px;
    padding: 10px 15px;
}

.happy_birthdays_inner ul li h3 {
    margin-bottom: 8px;
}

.happy_birthdays_inner ul li img {
    width: 56px;
    height: 56px;
    border-radius: 50%;
    object-fit: cover;
}

.pyro>.before,
.pyro>.after {
    position: absolute;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    box-shadow: -120px -218.66667px blue, 248px -16.66667px #00ff84,
        190px 16.33333px #002bff, -113px -308.66667px #ff009d,
        -109px -287.66667px #ffb300, -50px -313.66667px #ff006e,
        226px -31.66667px #ff4000, 180px -351.66667px #ff00d0,
        -12px -338.66667px #00f6ff, 220px -388.66667px #99ff00,
        -69px -27.66667px #ff0400, -111px -339.66667px #6200ff,
        155px -237.66667px #00ddff, -152px -380.66667px #00ffd0,
        -50px -37.66667px #00ffdd, -95px -175.66667px #a6ff00,
        -88px 10.33333px #0d00ff, 112px -309.66667px #005eff,
        69px -415.66667px #ff00a6, 168px -100.66667px #ff004c,
        -244px 24.33333px #ff6600, 97px -325.66667px #ff0066,
        -211px -182.66667px #00ffa2, 236px -126.66667px #b700ff,
        140px -196.66667px #9000ff, 125px -175.66667px #00bbff,
        118px -381.66667px #ff002f, 144px -111.66667px #ffae00,
        36px -78.66667px #f600ff, -63px -196.66667px #c800ff,
        -218px -227.66667px #d4ff00, -134px -377.66667px #ea00ff,
        -36px -412.66667px #ff00d4, 209px -106.66667px #00fff2,
        91px -278.66667px #000dff, -22px -191.66667px #9dff00,
        139px -392.66667px #a6ff00, 56px -2.66667px #0099ff,
        -156px -276.66667px #ea00ff, -163px -233.66667px #00fffb,
        -238px -346.66667px #00ff73, 62px -363.66667px #0088ff,
        244px -170.66667px #0062ff, 224px -142.66667px #b300ff,
        141px -208.66667px #9000ff, 211px -285.66667px #ff6600,
        181px -128.66667px #1e00ff, 90px -123.66667px #c800ff,
        189px 70.33333px #00ffc8, -18px -383.66667px #00ff33,
        100px -6.66667px #ff008c;
    -moz-animation: 1s bang ease-out infinite backwards,
        1s gravity ease-in infinite backwards,
        5s position linear infinite backwards;
    -webkit-animation: 1s bang ease-out infinite backwards,
        1s gravity ease-in infinite backwards,
        5s position linear infinite backwards;
    -o-animation: 1s bang ease-out infinite backwards,
        1s gravity ease-in infinite backwards,
        5s position linear infinite backwards;
    -ms-animation: 1s bang ease-out infinite backwards,
        1s gravity ease-in infinite backwards,
        5s position linear infinite backwards;
    animation: 1s bang ease-out infinite backwards,
        1s gravity ease-in infinite backwards,
        5s position linear infinite backwards;
}

.pyro>.after {
    -moz-animation-delay: 1.25s, 1.25s, 1.25s;
    -webkit-animation-delay: 1.25s, 1.25s, 1.25s;
    -o-animation-delay: 1.25s, 1.25s, 1.25s;
    -ms-animation-delay: 1.25s, 1.25s, 1.25s;
    animation-delay: 1.25s, 1.25s, 1.25s;
    -moz-animation-duration: 1.25s, 1.25s, 6.25s;
    -webkit-animation-duration: 1.25s, 1.25s, 6.25s;
    -o-animation-duration: 1.25s, 1.25s, 6.25s;
    -ms-animation-duration: 1.25s, 1.25s, 6.25s;
    animation-duration: 1.25s, 1.25s, 6.25s;
}

@-webkit-keyframes bang {
    from {
        box-shadow: 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
            0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
            0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
            0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
            0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
            0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
            0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
            0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
            0 0 white, 0 0 white, 0 0 white, 0 0 white;
    }
}

@-moz-keyframes bang {
    from {
        box-shadow: 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
            0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
            0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
            0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
            0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
            0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
            0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
            0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
            0 0 white, 0 0 white, 0 0 white, 0 0 white;
    }
}

@-o-keyframes bang {
    from {
        box-shadow: 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
            0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
            0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
            0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
            0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
            0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
            0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
            0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
            0 0 white, 0 0 white, 0 0 white, 0 0 white;
    }
}

@-ms-keyframes bang {
    from {
        box-shadow: 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
            0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
            0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
            0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
            0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
            0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
            0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
            0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
            0 0 white, 0 0 white, 0 0 white, 0 0 white;
    }
}

@keyframes bang {
    from {
        box-shadow: 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
            0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
            0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
            0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
            0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
            0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
            0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
            0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
            0 0 white, 0 0 white, 0 0 white, 0 0 white;
    }
}

@-webkit-keyframes gravity {
    to {
        transform: translateY(200px);
        -moz-transform: translateY(200px);
        -webkit-transform: translateY(200px);
        -o-transform: translateY(200px);
        -ms-transform: translateY(200px);
        opacity: 0;
    }
}

@-moz-keyframes gravity {
    to {
        transform: translateY(200px);
        -moz-transform: translateY(200px);
        -webkit-transform: translateY(200px);
        -o-transform: translateY(200px);
        -ms-transform: translateY(200px);
        opacity: 0;
    }
}

@-o-keyframes gravity {
    to {
        transform: translateY(200px);
        -moz-transform: translateY(200px);
        -webkit-transform: translateY(200px);
        -o-transform: translateY(200px);
        -ms-transform: translateY(200px);
        opacity: 0;
    }
}

@-ms-keyframes gravity {
    to {
        transform: translateY(200px);
        -moz-transform: translateY(200px);
        -webkit-transform: translateY(200px);
        -o-transform: translateY(200px);
        -ms-transform: translateY(200px);
        opacity: 0;
    }
}

@keyframes gravity {
    to {
        transform: translateY(200px);
        -moz-transform: translateY(200px);
        -webkit-transform: translateY(200px);
        -o-transform: translateY(200px);
        -ms-transform: translateY(200px);
        opacity: 0;
    }
}

@-webkit-keyframes position {

    0%,
    19.9% {
        margin-top: 10%;
        margin-left: 40%;
    }

    20%,
    39.9% {
        margin-top: 40%;
        margin-left: 30%;
    }

    40%,
    59.9% {
        margin-top: 20%;
        margin-left: 70%;
    }

    60%,
    79.9% {
        margin-top: 30%;
        margin-left: 20%;
    }

    80%,
    99.9% {
        margin-top: 30%;
        margin-left: 80%;
    }
}

@-moz-keyframes position {

    0%,
    19.9% {
        margin-top: 10%;
        margin-left: 40%;
    }

    20%,
    39.9% {
        margin-top: 40%;
        margin-left: 30%;
    }

    40%,
    59.9% {
        margin-top: 20%;
        margin-left: 70%;
    }

    60%,
    79.9% {
        margin-top: 30%;
        margin-left: 20%;
    }

    80%,
    99.9% {
        margin-top: 30%;
        margin-left: 80%;
    }
}

@-o-keyframes position {

    0%,
    19.9% {
        margin-top: 10%;
        margin-left: 40%;
    }

    20%,
    39.9% {
        margin-top: 40%;
        margin-left: 30%;
    }

    40%,
    59.9% {
        margin-top: 20%;
        margin-left: 70%;
    }

    60%,
    79.9% {
        margin-top: 30%;
        margin-left: 20%;
    }

    80%,
    99.9% {
        margin-top: 30%;
        margin-left: 80%;
    }
}

@-ms-keyframes position {

    0%,
    19.9% {
        margin-top: 10%;
        margin-left: 40%;
    }

    20%,
    39.9% {
        margin-top: 40%;
        margin-left: 30%;
    }

    40%,
    59.9% {
        margin-top: 20%;
        margin-left: 70%;
    }

    60%,
    79.9% {
        margin-top: 30%;
        margin-left: 20%;
    }

    80%,
    99.9% {
        margin-top: 30%;
        margin-left: 80%;
    }
}

@keyframes position {

    0%,
    19.9% {
        margin-top: 10%;
        margin-left: 40%;
    }

    20%,
    39.9% {
        margin-top: 40%;
        margin-left: 30%;
    }

    40%,
    59.9% {
        margin-top: 20%;
        margin-left: 70%;
    }

    60%,
    79.9% {
        margin-top: 30%;
        margin-left: 20%;
    }

    80%,
    99.9% {
        margin-top: 30%;
        margin-left: 80%;
    }
}

.all_hbd {
    background-color: #2eb8cd;
    background-image: url("../../assets/images/site/all_hbd.svg");
    background-position: center;
}

.add_birthday {
    height: calc(100vh - 60px);
    display: flex;
    align-items: center;
    justify-content: center;
}

.add_birthday_inner {
    width: 800px;
    padding: 25px;
    max-width: 90%;
    background: #ffffff;
    border: 2px solid #2eb8cd;
    border-radius: 5px;
    text-align: center;
}

.add_birthday input {
    width: 100%;
    height: 35px;
    margin: 40px 0;
    outline: 0;
}

.add_birthday .btn {
    padding: 10px;
    width: max-content;
    margin: 0 auto;
}

.add_birthday h1 {
    font-size: 26px;
    margin-bottom: 10px;
}

.add_graduate {
    min-width: 900px;
    height: 520px;
    margin: 0 auto;
    background-color: #fff;
    overflow: auto;
    display: flex;
    align-content: flex-start;
    flex-wrap: wrap;
    padding: 10px;
    gap: 10px;
    border-radius: 10px;
    box-shadow: 0 0 30px 0px rgba(46, 184, 205, 0.5);
}

.add_graduate li {
    width: 300px;
    height: max-content;
    padding: 5px;
    display: flex;
    gap: 15px;
    margin-bottom: 8px;
    align-items: center;
    cursor: pointer;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.35);
    padding: 5px;
    transition: 0.4s;
}

.add_graduate li:hover {
    transform: scale(1.05);
}

.add_graduate li b {
    font-size: 13px;
}

.add_graduate li img {
    width: 45px;
    height: 45px;
    border: 1px solid #000;
}

.add_graduate li h4 {
    margin-bottom: 10px;
}

.graduates_modal .add-modal-heading {
    margin: 30px 0;
}

.graduates_modal .btn {
    display: block;
    width: max-content;
    margin: 10px;
    margin-left: auto;
    padding: 10px 18px;
}

.graduates_modal .selected {
    color: #fff;
}

.selected-graduates {
    width: 500px;
    display: grid;
    grid-template-columns: repeat(3, auto);
    gap: 5px;
}

.graduates_modal .btn {
    display: flex;
    align-items: center;
    gap: 10px;
}

.make_student_again {
    background-color: #4834d4;
    margin-left: 10px;
}

.make_student_again img {
    width: 14px;
    height: 14px;
}

.heading {
    text-align: center;
    margin: 15px 0;
    font-size: 24px;
}

.books-list {
    width: 1140px;
    max-width: 95%;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 15px;
    padding: 30px 0;
}

.books-list li {
    overflow: hidden;
    text-align: center;
    cursor: pointer;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px,
        rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px,
        rgba(0, 0, 0, 0.09) 0px 32px 16px;
}

.books-list li b {
    margin-top: 5px;
    margin-bottom: 12px;
    display: block;
    font-size: 14px;
}

.books-list li p {
    margin-top: 12px;
}

.books-list li img {
    width: 100%;
    height: 320px;
    transition: 0.4s;
}

.book-info {
    padding: 12px;
}

.book-cover {
    height: 320px;
    overflow: hidden;
    position: relative;
}

.download-book {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 15px 25px;
    background-color: #00bbff;
    font-weight: bold;
    border: 0;
    color: #fff;
    cursor: pointer;
    font-size: 16px;
    transform: translateY(100%) scale(0.8);
    opacity: 0;
    transition: 0.3s;
}

.books-list li:hover .download-book {
    transform: translateY(0) scale(1);
    opacity: 1;
}

.books-list li:hover img {
    transform: scale(1.05);
}

.download-book::before {
    content: "";
    width: 25px;
    height: 25px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 20px;
    background-image: url("../../assets/icons/downloadBook.svg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.add-book {
    width: max-content;
    margin: 10px auto;
    padding: 10px 25px;
    position: relative;
    overflow: hidden;
    z-index: 0;
}

.add-book .u-percentage {
    height: 100%;
    background-color: red;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    display: flex;
    align-items: center;
    transition: 0.3s;
}

.add-book:hover::before {
    animation-name: hvr-ripple-out;
}

.add-book:not(:disabled)::before {
    content: "";
    position: absolute;
    border: #2eb8cd solid 3px;
    border-radius: 5px;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    animation-duration: 1s;
}

@keyframes hvr-ripple-out {
    100% {
        top: -12px;
        right: -12px;
        bottom: -12px;
        left: -12px;
        opacity: 0;
    }
}

.add-book-form {
    width: 100%;
    height: 100vh;
    overflow: auto;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    backdrop-filter: blur(5px);
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.add-book-form form {
    box-shadow: 0 0 10px #2eb8cd;
    background-color: #fff;
    width: 600px;
    max-width: 95%;
    padding: 20px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    position: relative;
    gap: 10px;
}

.add-book-form form h1 {
    text-align: center;
}

.add-book-form form input {
    display: block;
    width: 100%;
    min-height: 30px;
    margin: 10px 0;
    padding: 10px;
    border: 1px solid #2eb8cd;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
}

.add-book-form form .btn {
    height: auto;
    transition: 0.4s;
}

.delete-book {
    padding: 8px 16px;
    border-radius: 10px;
    font-size: 16px;
    background-color: #fd1014;
    color: #fff;
    display: inline-block;
    margin: 10px 0;
    border: 0;
    cursor: pointer;
}

#close-book-modal {
    width: 25px;
    height: 25px;
    filter: brightness(0);
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
}

.create_online_lesson {
    font-size: 14px;
    color: #63b22f;
    background: #f0f0f0;
    border: 1px solid #63b22f;
    border-radius: 5px;
    padding: 10px 20px;
    margin-top: 16px;
    cursor: pointer;
    font-weight: bold;
    display: inline-block;
    text-transform: uppercase;
    transition: 0.3s;
    letter-spacing: 1px;
}

.create_online_lesson:hover {
    background-color: #63b22f;
    border-color: #f0f0f0;
    color: #f0f0f0;
}

.destroy_online_lesson {
    font-size: 14px;
    color: rgb(242, 69, 69);
    background: #f0f0f0;
    border: 1px solid rgb(242, 69, 69);
    border-radius: 5px;
    padding: 10px 20px;
    margin-top: 16px;
    cursor: pointer;
    font-weight: bold;
    display: inline-block;
    text-transform: uppercase;
    transition: 0.3s;
    letter-spacing: 1px;
    display: flex;
    gap: 5px;
    align-items: center;
}

.destroy_online_lesson:hover {
    background-color: rgb(242, 69, 69);
    border-color: #f0f0f0;
    color: #f0f0f0;
}

.destroy_online_lesson .loading-spinner {
    border-color: rgb(242, 69, 69) !important;
    border-right-color: transparent !important;
}

.destroy_online_lesson:hover .loading-spinner {
    border-color: #fff !important;
    border-right-color: transparent !important;
}

.add_online_lesson_modal_container {
    width: 100%;
    height: 100vh;
    backdrop-filter: blur(3px);
    background-color: rgba(0, 0, 0, 0.3);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.add_online_lesson_modal_container form {
    width: 480px;
    max-width: 95%;
    height: 350px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    padding: 30px;
    border: 2px solid #2eb8cd;
    border-radius: 10px;
    position: relative;
}

.add_online_lesson_modal_container form select,
.add_online_lesson_modal_container form textarea {
    width: 100%;
    display: block;
    outline: none;
    border-radius: 5px;
    margin-top: 10px;
    background-color: #fff;
    padding: 8px;
}

.add_online_lesson_modal_container form textarea {
    height: 100px;
    resize: none;
}

.add_online_lesson_modal_container form .btn {
    width: max-content;
    height: auto;
    padding: 10px 20px;
    margin-left: auto;
}

.add_online_lesson_modal_container form label {
    margin-bottom: 30px;
}

.add_online_lesson_modal_container form .text-danger {
    margin-top: 10px;
    display: inline-block;
}

#close_online_lesson_modal {
    width: 30px;
    height: 30px;
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
    filter: brightness(0);
}

.student_inner_data {
    display: flex;
    align-items: center;
    gap: 28px;
}

.join_online_lesson {
    width: max-content;
    background: #f0f0f0;
    border: 1px solid #d6d6d6;
    border-radius: 5px;
    padding: 10px 30px;
    margin-top: 20px;
    font-weight: 400;
    font-size: 16px;
    cursor: not-allowed;
    transition: 0.3s;
    font-weight: 700;
}

.join_online_lesson.has-lesson {
    border-color: #63b22f;
    color: #63b22f;
    cursor: pointer;
}

.join_online_lesson.has-lesson:hover {
    border-color: #f0f0f0;
    color: #f0f0f0;
    background-color: #63b22f;
}

.search_by_name {
    width: 100%;
    background-color: #fff;
    text-align: center;
    padding: 40px;
}

.search_by_name input {
    margin-top: 10px;
    border: 1px solid #000;
    height: 30px;
    width: 220px;
    padding: 5px;
    outline: 0;
}

.network__notify {
    max-width: 95%;
    position: absolute;
    bottom: 10px;
    right: 10px;
    z-index: 99;
}

.network__notify .alert {
    display: flex;
    align-items: center;
    padding: 0.55rem 0.65rem 0.55rem 0.75rem;
    border-radius: 1rem;
    justify-content: space-between;
    box-shadow: 0px 3.2px 13.8px rgba(0, 0, 0, 0.02),
        0px 7.6px 33.3px rgba(0, 0, 0, 0.028),
        0px 14.4px 62.6px rgba(0, 0, 0, 0.035),
        0px 25.7px 111.7px rgba(0, 0, 0, 0.042),
        0px 48px 208.9px rgba(0, 0, 0, 0.05),
        0px 115px 500px rgba(0, 0, 0, 0.07);
}

.network__notify .content {
    display: flex;
    align-items: center;
    color: #fff;
    font-weight: bold;
}

.network__notify .icon {
    padding: 0.5rem;
    margin-right: 1rem;
    border-radius: 39% 61% 42% 58% / 50% 51% 49% 50%;
    box-shadow: 0px 3.2px 13.8px rgba(0, 0, 0, 0.02),
        0px 7.6px 33.3px rgba(0, 0, 0, 0.028),
        0px 14.4px 62.6px rgba(0, 0, 0, 0.035),
        0px 25.7px 111.7px rgba(0, 0, 0, 0.042),
        0px 48px 208.9px rgba(0, 0, 0, 0.05),
        0px 115px 500px rgba(0, 0, 0, 0.07);
}

.network__notify .success {
    background-color: rgba(62, 189, 97, 0.7);
    border: 2px solid #000;
}

.network__notify .success .icon {
    background-color: #3ebd61;
}

.network__notify .danger {
    background-color: rgba(236, 77, 43, 0.7);
    border: 2px solid #000;
}

.network__notify .danger .icon {
    background-color: #ec4d2b;
}

/* Voting */

.voting {
    max-width: 425px;
    margin: 0 auto;
}

/* Header */

.voting header {
    background: linear-gradient(55.87deg, #2eb8cd 1.68%, #63b22f 95.59%);
    border-bottom-right-radius: 50%;
    padding: 15px;
    height: max-content;
    display: block;
    padding-bottom: 10px;
}

.voting header h2 {
    font-size: 28px;
    color: #fff;
    text-align: center;
    margin: 20px 0;
}

.voting header ul {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    margin-top: 15px;
}

.voting header ul li {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.voting header ul img {
    width: 53px;
    height: 53px;
    object-fit: cover;
    border-radius: 50%;
    margin-right: 15px;
    border: 2px solid rgba(255, 255, 255, 0.3);
}

.voting header ul li p {
    font-size: 20px;
    color: #fff;
    font-weight: 500;
    line-height: 24px;
}

/* Form */

.voting #form {
    padding: 20px 30px;
}

.voting #form h3 {
    text-align: center;
    font-size: 24px;
    font-weight: 400;
}

.voting #form input {
    outline: 0;
    background-color: #f8f8f9;
    height: 100%;
    text-align: center;
    width: 100%;
    margin: 20px auto;
    border: 0;
    font-size: 22px;
}

.voting .linear-border {
    padding: 2px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
    background: linear-gradient(55.87deg, #2eb8cd 1.68%, #63b22f 95.59%);
}

/* Point */
.voting #point {
    text-align: center;
    padding: 15px;
}

.voting #point h3 {
    text-align: center;
    font-size: 24px;
    font-weight: 400;
    margin-bottom: 5px;
}

.voting #point button {
    background: #2eb8cd;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    padding: 10px 15px;
    font-size: 17px;
    color: #fff;
    border: none;
    font-weight: bold;
    margin-top: 10px;
    cursor: pointer;
}

.voting .valuation {
    width: 100%;
    background-color: #f8f8f9;
    height: 50px;
    margin: 30px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.vote-range-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    position: relative;
    padding-top: 60px;
}

.vote-range-container .current {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0%);
    font-size: 30px;
    background: #2eb8cd;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    color: #fff;
}

.vote-range-container [type="range"] {
    appearance: none;
    -webkit-appearance: none;
    background: #2eb8cd;
    border-radius: 10px;
    height: 15px;
    width: 280px;
    border: 1px solid rgba(0, 0, 0, 0.8);
    padding: 2px;
    box-sizing: initial;
}

.vote-range-container input[type="range"]::-webkit-slider-thumb {
    width: 15px;
    appearance: none;
    -webkit-appearance: none;
    height: 15px;
    background: #fff;
    cursor: pointer;
    border-radius: 10px;
}

.single-participiant img {
    width: 137px !important;
    height: 137px !important;
    margin-bottom: 10px;
}

.single-participiant {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.new_guest {
    width: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 15px;
    margin: 10px auto;
}

.new_guest * {
    width: 100%;
    height: 40px;
}

.guests_table {
    width: 90%;
    max-width: 1300px;
    margin: 20px auto;
    text-align: center;
}

.guests_table,
.guests_table td,
.guests_table th {
    border: 1px solid #000;
    border-collapse: collapse;
    padding: 10px;
}

.vote_logo_dark {
    display: block;
    width: 130px;
    margin: 15px auto;
}

/* Dark Mode Switcher */
/* .dark__mode__switcher {
    width: 46px;
    height: 24px;
    border: 1px solid gray;
    border-radius: 14px;
    position: relative;
}
.dark__mode__switcher__list a:hover {
    filter: none;
}
.dark__mode__switcher::before {
    content: "";
    width: 20px;
    height: 20px;
    background-color: #333333;
    box-sizing: border-box;
    border: 1px solid gray;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 1px;
    transition: 0.3s;
    transform: translateY(-50%);
}
body.dark__mode .dark__mode__switcher::before {
    left: calc(100% - 1px);
    transform: translate(-100%, -50%);
    background-color: #00fffb;
} */
/* Dark Mode Switcher End*/
#page__changing {
    position: fixed;
    top: 0;
    left: 0;
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: #fff;
}

header .logo a {
    position: relative;
}

#santa__hat {
    width: 35px;
    height: 35px;
    right: 0;
    top: 0;
    transform: translate(45%, -50%);
    position: absolute;
}

.snowing__effect {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 9999;
    background-image: url("../../assets//images/site/snow00.gif");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    pointer-events: none;
}

#santa {
    position: fixed;
    top: 50%;
    right: -500px;
    transform: translateY(-50%);
    animation: santa 10s 1s forwards linear;
}

@keyframes santa {
    to {
        right: 100%;
    }
}

.voting {
    padding: 16px;
}

.voting ul li h4 {
    font-family: "CodecPro-Regular";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: #0e9594;
}

.voting h4 {
    font-family: "CodecPro-Regular";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    color: #000000;
    margin-top: 32px;
    margin-bottom: 20px;
}

.vote__pin__form input {
    outline: 0;
    width: 170px;
    height: 30px;
    margin: 0 auto;
    display: block;
    background: #ffffff;
    border: 1px solid #0e9594;
    border-radius: 3px;
    padding: 5px;
}

.voting h1 span {
    font-weight: 400;
}

.voting .part img {
    width: 45px;
    height: 45px;
    margin-right: 7px;
    border-radius: 50%;
    object-fit: cover;
    object-position: center;
}

.voting .part {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    padding: 10px 16px;
    background: #ffffff;
    box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.09);
    border-radius: 5px;
}

.voting .part li {
    display: flex;
    align-items: center;
}

.vot__logo {
    display: block;
    margin: 0 auto;
}

.voting h3 {
    font-family: "CodecPro-Regular";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    color: #000000;
    margin-top: 32px;
    margin-bottom: 5px;
}

.vote {
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    padding: 14px 23px;
    font-style: normal;
    font-weight: 700;
    font-size: 15.9198px;
    line-height: 19px;
    color: #0e9594;
    font-family: "CodecPro-Regular";
    gap: 16px;
    background: #ffffff;
    box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.09);
    border-radius: 5px;
}

.vote div {
    width: calc(100% / 5 - (16px - 16px / 5));
    background: #ffffff;
    aspect-ratio: 1/1;
    box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.08);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.vote div.active {
    background-color: #0e9594;
    color: #fff !important;
}

.voting button {
    background: #0e9594;
    border-radius: 5px;
    padding: 8px 27px;
    border: 0;
    display: block;
    margin: 32px auto 0 auto;
    color: #fff;
    font-family: "CodecPro-Regular";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    color: #ffffff;
}

.march8 {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: url("../../assets/images/site/clip_march8.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    z-index: 7777;
}

.march8::before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgb(196, 154, 108);
    mix-blend-mode: multiply;
    z-index: -1;
}

.row8 {
    width: 740px;
    max-width: 95%;
    margin: 0 auto;
    margin-top: 96px;
    position: relative;
}

.row8 svg {
    width: 472px;
    max-width: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-30%, -50%);
}

.row8__data {
    width: 740px;
    margin: 0 auto;
    max-width: 95%;
    color: #fff;
    min-height: 275px;
}

.row8__data p {
    font-size: 26px;
    line-height: 28px;
    margin-top: 16px;
}

.row8__data h3 {
    font-weight: 500;
    font-size: 40px;
    line-height: 34px;
}

@media screen and (max-width: 768px) {
    .row8__data p {
        font-size: 14px;
        line-height: 22px;
        margin-top: 16px;
    }

    .row8__data h3 {
        font-weight: 500;
        font-size: 18px;
        line-height: 16px;
    }

    .row8__logo {
        margin-top: 36px !important;
        justify-content: space-between !important;
        position: relative;
    }

    .row8__logo>svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .row8__data {
        min-height: 170px;
    }

    .row__icon__next,
    .row__icon__prev {
        display: none;
    }

    #demo333 {
        display: block !important;
        position: absolute;
        top: 50%;
        left: 10px;
        transform: translateY(-50%);
    }

    #demo444 {
        display: block !important;
        position: absolute;
        top: 50%;
        right: 10px;
        transform: translateY(-50%);
    }

    .last__march img {
        display: none;
    }

    #mobile__march {
        display: block !important;
    }
}

#mobile__march {
    display: none;
}

.row8 img {
    width: 400px;
    max-width: 45%;
    transform: translateX(-20%);
}

.row8__logo {
    width: 740px;
    max-width: 95%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    margin-top: 116px;
    align-items: center;
}

.row8__logo div {
    display: none;
}

.row8__logo svg {
    opacity: 0.6;
}

.row__icon__next svg {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
}

.row__icon__prev svg {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
}

.last__march {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
}

.last__march img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.last__march svg {
    width: 5%;
    height: 5%;
    position: absolute;
    top: 5%;
    right: 20%;
    cursor: pointer;
}

.novruz {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 3;
    background-image: url("../../assets/images/site/novruz_bg.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    text-align: center;
    padding-top: 80px;
}

.novruz .novruz__flower {
    position: absolute;
    top: 100%;
    left: 0;
    width: 70%;
    transform: translateY(-100%);
}

.novruz h3 {
    color: #fff;
    width: 75%;
    margin: 0 auto;
    margin-top: 60px;
    font-size: 56px;
    z-index: 4;
    position: relative;
    line-height: 70px;
}

.novruz .close {
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer;
    transform: scale(1.5);
}

/* Deadline modal start */

.deadline__modal__container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 9;
    backdrop-filter: blur(8px);
    background-color: rgba(255, 255, 255, 0.4);
}

.deadline__modal__inner {
    width: 840px;
    max-width: 95%;
    text-align: center;
    background-color: #fff;
    position: absolute;
    top: 10%;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 8px;
    padding: 115px 0 62px 0;
    box-shadow: 0px 0px 9px 4px rgba(0, 0, 0, 0.1);
}

#close__deadline {
    position: absolute;
    right: 15px;
    top: 15px;
    cursor: pointer;
}

.deadline__modal__inner h1 {
    margin-top: 60px;
    font-weight: 400;
    font-size: 35px;
    line-height: 37px;
}

.modal-image {
    width: max-content;
    margin: 0 auto;
    position: relative;
    z-index: 0;
    max-width: 100%;
}

.modal-image::before {
    content: "";
    width: 469px;
    height: 469px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: radial-gradient(41.58% 41.58% at 50% 50%,
            #ffb3c1 0%,
            rgba(243, 179, 191, 0) 100%);
    background-size: cover;
    background-position: center;
    z-index: -1;
}

/* Deadline modal end */

/* Barmen Start */
.menu__heading {
    text-align: center;
    margin: 10px 0;
}

.menu__crud {
    margin-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.menu__crud button {
    border: 2px solid #000;
    padding: 10px 16px;
    color: #fff;
    cursor: pointer;
    font-weight: bold;
}

.menu__crud button.new__item {
    background-color: #2fb7cd;
    display: flex;
    justify-content: center;
    align-items: center;
}

.menu__crud button.new__cat {
    background-color: #2fb7cd;
}

.cafe__content {
    width: 1200px;
    max-width: 95%;
    margin: 10px auto;
}

.barmen__modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(5px);
    display: flex;
    justify-content: center;
    z-index: 999;
}

.barmen__modal form {
    transform: translateY(200px);
    width: 400px;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    padding: 20px;
    border-radius: 10px 15px;
    height: max-content;
    gap: 10px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
}

.barmen__modal form input,
.barmen__modal form button,
.barmen__modal form select {
    height: 35px;
    border: 1px solid #000;
    outline: 0;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.barmen__modal form button {
    cursor: pointer;
    background-color: #00ddff;
    color: #fff;
    font-weight: bold;
    font-size: 18px;
}

.cafe__table {
    width: 100%;
    text-align: center;
}

.cafe__table,
.cafe__table td,
.cafe__table th {
    border: 1px solid #000;
    border-collapse: collapse;
    padding: 5px;
}

.cafe__table button {
    border: 1px solid #000;
    padding: 5px 10px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    vertical-align: middle;
    font-weight: bold;
    font-size: 16px;
    color: #fff;
    cursor: pointer;
}

.cafe__table button.edit {
    background-color: #feca57;
}

.cafe__table button.delete {
    background-color: #fd1014;
    margin-left: 10px;
}

.cafe__table td:first-of-type {
    width: 50px;
}

.cafe__table td:last-of-type {
    width: 290px;
}

.edit_cat_name {
    height: 35px;
    outline: 0;
    border: 1px solid #000;
    width: 280px;
    padding: 5px 10px;
}

.error {
    color: #fd1014;
}

.has__error {
    border: 1px solid #fd1014 !important;
}

.cafe__section {
    background: #e8d9c8;
    padding-top: 72px;
    padding-bottom: 50px;
}

.cafe__heading {
    text-align: center;
}

.cafe__heading img {
    max-width: 95%;
}

.cafe__bg {
    width: 1200px;
    max-width: 90%;
    min-height: 100vh;
    margin: 65px auto;
    margin-bottom: 0;
    background-image: url("../../assets/images/site/cafe_bg_1.png");
}

.cafe__bg ul {
    width: 750px;
    max-width: 95%;
    margin: 24px auto;
    font-weight: 400;
    font-size: 26px;
    line-height: 37px;
}

@font-face {
    font-family: "cafe-font";
    src: url("../../assets/fonts/cafeFont.otf");
}

.cafe__bg h3 {
    font-family: "cafe-font";
    text-align: center;
    font-weight: 400;
    font-size: 40px;
    line-height: 37px;
}

.price__list {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.price__list span:first-of-type {
    position: relative;
    display: block;
    width: calc(100% - 24px);
    overflow: hidden;
}

.price__list span:last-of-type {
    width: 180px;
    text-align: right;
    display: block;
}

.price__list span:first-of-type::after {
    content: ".................................................................................................................................................................................................................................";
    position: absolute;
}

.cafe__logo__bottom {
    display: block;
    margin: 0 auto;
    width: 230px;
    margin-top: 100px;
}

.cafe__offer {
    width: max-content;
    margin: 0 auto;
    position: relative;
}

.cafe__offer::after {
    content: "";
    width: 90px;
    height: 75px;
    background-image: url("../../assets/images/site/cafe__decor.svg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(100%, -50%) rotate(10deg);
}

/* Barmen End */
.postfix_email {
    position: absolute;
    right: 0;
    bottom: 0;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #acacac;
    color: #fff;
    padding: 5px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.relative {
    position: relative;
}